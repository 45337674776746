<template>
  <the-header></the-header>
  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <main>
    <router-view v-slot="slotProps">
      <transition name="route" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </main>
</template>

<script>
import TheHeader from './components/TheHeader.vue'

export default {
  components: { TheHeader },
  name: 'App',
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout
    },
  },
  created() {
    this.$store.dispatch('tryLogin')
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/login')
      }
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

:root {
  --color1: #e4f5ff;
  --color2: #96c7d5;
  --color3: #2d7a9c;
  --color4: #094f6e;
  --color5: #022534;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  background-color: var(--color5);
  color: var(--color1);
  font-family: 'Muli', sans-serif;
  font-size: 1.5rem;
  min-height: 100vh;
}

.btn {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  background: var(--color2);
  padding: 1.5rem;
  font-family: inherit;
  font-size: 2rem;
  border: 0;
  border-radius: 0.5rem;
}

.btn:focus {
  outline: 0;
}

.btn:active {
  transform: scale(0.98);
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
