import { createStore } from 'vuex'
import { sortDataIntervals } from '../scripts/sort-functions.mjs'
import { Indexes, Categories } from '../scripts/global-data.mjs'

let timer

const store = createStore({
  state() {
    return {
      userId: null,
      masterId: '91sqMcDefUgpF9qq4mAGK1mh60I2',
      token: null,
      didAutoLogout: false,
      data: null,
      records: null,
      dataIntervals: null,
      intervalAverages: null,
    }
  },
  getters: {
    userId(state) {
      return state.userId
    },
    provider(state) {
      let provider

      switch (state.userId) {
        case 'Qk0RLEtKrDWScBB4EFuU5OGsCMD2':
          provider = 'Kristin Jackson'
          break
        case '2':
          provider = 'Joel Mickail'
          break
        case '3':
          provider = 'Patrick Marinas'
          break
        case '4':
          provider = 'Corey Page'
          break
        case '5':
          provider = 'Josh Roszak'
          break
        case '6':
          provider = 'Shelly Therrien'
          break
        case '7':
          provider = 'Mohammad Alzahri'
          break
        case '8':
          provider = 'Corey Anderson'
          break
        case '9':
          provider = 'Maurice Holmes'
          break
        case '10':
          provider = 'Pin Lam'
          break
        case '11':
          provider = 'Roman Nieto'
          break
        case '15':
          provider = 'Setul Patel'
          break
        case '12':
          provider = 'Kevin Rittger'
          break
        case '13':
          provider = 'Phillip Singer'
          break
        case '14':
          provider = 'Terrence Wadley'
          break

        default:
          provider = null
      }

      return provider
    },
    token(state) {
      return state.token
    },
    isAuthenticated(state) {
      return !!state.token
    },
    isMaster(state) {
      return state.userId === state.masterId
    },
    didAutoLogout(state) {
      return state.didAutoLogout
    },
    data(state) {
      return state.data
    },
    records(state) {
      return state.records
    },
    dataIntervals(state) {
      return state.dataIntervals
    },
    intervalAverages(state) {
      return state.intervalAverages
    },
  },
  mutations: {
    setUser(state, payload) {
      state.token = payload.token
      state.userId = payload.userId
      state.didAutoLogout = false
      state.data = payload.data
      state.records = payload.records
      state.dataIntervals = payload.dataIntervals
      state.intervalAverages = payload.intervalAverages
    },
    setData(state, payload) {
      state.data = payload.data
    },
    setRecords(state, payload) {
      state.records = payload.records
    },
    setDataIntervals(state, payload) {
      state.dataIntervals = payload.dataIntervals
    },
    setIntervalAverages(state, payload) {
      state.intervalAverages = payload.intervalAverages
    },
    setAutoLogout(state) {
      state.didAutoLogout = true
    },
  },
  actions: {
    async loadData(context) {
      const url =
        'https://ms4m2-37941-default-rtdb.firebaseio.com/data.json?auth=' +
        context.getters.token
      const response = await fetch(url)
      const responseData = await response.json()

      if (!response.ok) {
        const error = new Error(
          responseData?.error?.message || 'Failed to load data',
        )
        throw error
      }

      context.commit('setData', {
        data: responseData,
      })

      localStorage.setItem('data', JSON.stringify(responseData))
    },
    async auth(context, payload) {
      let url =
        'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBd0LgxVmi_1I28BGOCJbRun0sNSmB-4WA'
      let defaultErrorMsg = 'Failed to authenticate.'

      if (payload.mode === 'signup') {
        url =
          'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyBd0LgxVmi_1I28BGOCJbRun0sNSmB-4WA'
        defaultErrorMsg = 'Sign up failed.'
      }

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          email: payload.email,
          password: payload.password,
          returnSecureToken: true,
        }),
      })

      const responseData = await response.json()

      if (!response.ok) {
        const error = new Error(responseData?.error?.message || defaultErrorMsg)
        throw error
      }

      const expiresIn = +responseData.expiresIn * 1000
      const expiraationDate = new Date().getTime() + expiresIn

      localStorage.setItem('token', responseData.idToken)
      localStorage.setItem('userId', responseData.localId)
      localStorage.setItem('tokenExpiration', expiraationDate)

      timer = setTimeout(function () {
        context.dispatch('autoLogout')
      }, expiresIn)

      context.commit('setUser', {
        token: responseData.idToken,
        userId: responseData.localId,
        data: null,
        records: null,
        dataIntervals: null,
        intervalAverages: null,
      })

      try {
        await context.dispatch('loadData')
        context.dispatch('constructRecordSet')
        context.dispatch('constructDataIntervals')
        context.dispatch('constructAverageIntervals')
      } catch (err) {
        this.error = 'Error message: ' + (err.message || 'Failed to load data.')
        console.log(this.error)
      }
    },
    tryLogin(context) {
      const token = localStorage.getItem('token')
      const userId = localStorage.getItem('userId')
      const tokenExpiraation = localStorage.getItem('tokenExpiraation')
      const data = JSON.parse(localStorage.getItem('data'))
      const records = JSON.parse(localStorage.getItem('records'))
      const dataIntervals = JSON.parse(localStorage.getItem('dataIntervals'))
      const intervalAverages = JSON.parse(
        localStorage.getItem('intervalAverages'),
      )

      if (tokenExpiraation) {
        const expiresIn = +tokenExpiraation - new Date().getTime()

        if (expiresIn < 10000) {
          return
        }

        timer = setTimeout(function () {
          context.dispatch('autoLogout')
        }, expiresIn)
      }

      if (token && userId) {
        context.commit('setUser', {
          token,
          userId,
          data,
          records,
          dataIntervals,
          intervalAverages,
        })
      }
    },
    logout(context) {
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      localStorage.removeItem('tokenExpiration')
      localStorage.removeItem('data')
      localStorage.removeItem('records')
      localStorage.removeItem('dataIntervals')

      clearTimeout(timer)

      context.commit('setUser', {
        token: null,
        userId: null,
        data: null,
        records: null,
        dataIntervals: null,
        intervalAverages: null,
      })
    },
    autoLogout(context) {
      context.dispatch('logout')
      context.commit('setAutoLogout')
    },
    async login(context, payload) {
      return context.dispatch('auth', {
        ...payload,
        mode: 'login',
      })
    },
    async signup(context, payload) {
      return context.dispatch('auth', {
        ...payload,
        mode: 'signup',
      })
    },
    constructRecordSet(context) {
      const Records = []
      const Data = context.getters.data

      /* 
      
      data
        APPs
          2020
            06-01 06-15
              Corey Page
                251, 160, 165, 83, 31, 20
              Joel Mickail
                198, 138, 122, 80, 59, 26
              ...


      */

      for (let providerList in Data) {
        let years = Data[providerList]
        const _list = providerList.toLowerCase() // 'apps' or 'physicians'

        for (let year in years) {
          const intervals = years[year]

          for (let interval in intervals) {
            const _interval = interval.split(' ')
            const __interval = _interval[0].split('-')
            const [month, day] = __interval
            const providers = intervals[interval]

            for (let provider in providers) {
              const vector = providers[provider]

              const _record = [
                provider,
                _list,
                +year,
                +month,
                +day,
                interval,
                ...vector,
              ]
              Records.push(_record)
            }
          }
        }
      }

      /* 
        ['Corey Page', 'apps', 2020, 6, 1, '06-01 06-15', [251, 160, 165, 83, 31, 20]]
        ['Joel Mickail', 'apps', 2020, 6, 1, '06-01 06-15', [198, 138, 122, 80, 59, 26]]
      */

      context.commit('setRecords', {
        records: Records,
      })

      localStorage.setItem('records', JSON.stringify(Records))
    },
    constructAverageIntervals(context) {
      const intervals = context.getters.dataIntervals
      const records = context.getters.records
      const categories = Object.keys(Categories)
      const intervalAverages = {}

      function setItem() {
        return {
          sum: 0,
          n: 0,
          avg: null,
        }
      }

      for (let interval of intervals) {
        const _interval = `${interval[1]} ${interval[0]}`
        intervalAverages[_interval] = {}

        for (let category of categories) {
          intervalAverages[_interval][category] = setItem()
        }
      }

      function addValue(i, v) {
        const o = {
          ...i,
        }

        if (v !== null && v !== undefined) {
          o.sum += v
          o.n += 1
          o.avg = Math.round(o.sum / o.n)
        }

        return o
      }

      for (let record of records) {
        const _index = `${record[Indexes.INTERVAL]} ${record[Indexes.YEAR]}`
        const _interval = intervalAverages[_index]

        for (let category of categories) {
          _interval[category] = addValue(
            _interval[category],
            record[Indexes[category]],
          )
        }
      }

      console.log(intervalAverages)

      context.commit('setIntervalAverages', {
        intervalAverages,
      })

      localStorage.setItem('intervalAverages', JSON.stringify(intervalAverages))
    },
    constructDataIntervals(context) {
      const Intervals = {}
      const Data = context.getters.data

      // collapse duplicates
      for (let providerList in Data) {
        let years = Data[providerList]

        for (let year in years) {
          if (!Intervals[year]) Intervals[year] = new Set()

          const intervals = years[year]

          for (let interval in intervals) {
            Intervals[year].add(interval)
          }
        }
      }

      const DataIntervals = []

      // convert to array
      Object.keys(Intervals).forEach((year) => {
        const _set = Intervals[year]

        for (let interval of _set) {
          DataIntervals.push([year, interval])
        }
      })

      DataIntervals.sort(sortDataIntervals)

      context.commit('setDataIntervals', {
        dataIntervals: DataIntervals,
      })

      localStorage.setItem('dataIntervals', JSON.stringify(DataIntervals))
    },
  },
})

export default store
