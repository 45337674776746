<template>
  <div class="main">
    <nav id="nav" class="nav">
      <pages-navigation></pages-navigation>
    </nav>
    <section id="stats-ii">
      <div id="rows" class="rows">
        <div class="row">
          <comparisons-actions
            :deltaCutoff="deltaCutoff"
            :maxIntervalIndex="params.maxIntervalIndex"
            :start="params.request.range.start"
            :end="params.request.range.end"
            @range-slider-handler="rangeSliderHandler"
            @interval-range-slider-handler="intervalRangeSliderHandler"
          ></comparisons-actions>
        </div>
        <div
          class="row"
          v-for="(result, index) in results"
          :key="index"
          :id="result.category"
        >
          <display-container
            :category="result.category"
            :result="result"
            :globalMean="result.data['ALL'].stats.mean"
            :globalMin="result.data['ALL'].stats.min"
            :deltaCutoff="deltaCutoff"
          ></display-container>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { calculateStats } from '../scripts/stats-functions.mjs'
import { Categories, Providers, Indexes } from '../scripts/global-data.mjs'
import DisplayContainer from './DisplayContainer.vue'
import ComparisonsActions from './ComparisonsActions.vue'
import PagesNavigation from './PagesNavigation.vue'

const _params = {
  bucketSize: 5,
}

function constructDataSetRequest(request, category) {
  const { esi, phase } = Categories[category]
  return {
    esi,
    phase,
    ...request,
  }
}

function constructProviderDataSet(request, vector, stats, DataIntervals) {
  const { provider, range } = request
  const [start_year, start_interval] = DataIntervals[range.start]
  const range_start = `${start_interval} ${start_year}`
  const [end_year, end_interval] = DataIntervals[range.end]
  const range_end = `${end_interval} ${end_year}`

  return {
    provider: providerPretty(provider),
    range_start,
    range_end,
    data: vector,
    stats,
    request,
  }
}

function providerPretty(provider) {
  return provider ? provider : 'ALL'
}

function getRequestedDataVector(request, Records, DataIntervals) {
  const vector = []
  const { provider, esi, phase, range } = request
  const catIndex = `esi${esi}_${phase}`
  const dataSetIndex = Indexes[catIndex]

  for (let record of Records) {
    const alpha = isSelectedProvider(provider, record)
    const gamma = isAfterEqualStart(range, record, DataIntervals)
    const delta = isBeforeEqualEnd(range, record, DataIntervals)

    if (!alpha) {
      continue
    }
    if (!gamma) {
      continue
    }
    if (!delta) {
      continue
    }

    const dataPoint = record[dataSetIndex]
    vector.push(dataPoint)
  }

  return vector
}

function isAfterEqualStart(range, record, DataIntervals) {
  if (!range) return true

  const { start } = range
  if (isNaN(parseInt(start))) return true

  const [year, interval] = DataIntervals[start]

  const _interval = interval.split('-')
  const [month, day] = _interval[0].split('/')

  const record_year = record[Indexes.YEAR]
  const record_month = record[Indexes.MONTH]
  const record_day = record[Indexes.DAY]

  if (record_year < year) return false
  if (record_year > year) return true
  if (record_month < month) return false
  if (record_month > month) return true
  if (record_day < day) return false
  return true
}

function isBeforeEqualEnd(range, record, DataIntervals) {
  if (!range) return true
  const { end } = range
  if (isNaN(parseInt(end))) return true

  const [year, interval] = DataIntervals[end]

  const _interval = interval.split('-')
  const [month, day] = _interval[0].split('/')

  const record_year = record[Indexes.YEAR]
  const record_month = record[Indexes.MONTH]
  const record_day = record[Indexes.DAY]

  if (record_year > year) return false
  if (record_year < year) return true
  if (record_month > month) return false
  if (record_month < month) return true
  if (record_day > day) return false
  return true
}

function isSelectedProvider(provider, record) {
  if (!provider) return true
  const record_name = record[Indexes.PROVIDER]
  return provider === record_name
}

function constructDataSetRequestForProvider(request, category, provider) {
  const r = constructDataSetRequest(request, category)
  const provider_id = Providers[provider].index
  return {
    ...r,
    provider,
    provider_id,
  }
}

function createBucketDistribution(stats, globalMin, globalMax) {
  if (!stats) return null
  const { distribution, min } = stats
  const { bucketSize } = _params
  const globalLength = globalMax - globalMin + 1
  const numBuckets = Math.ceil(globalLength / bucketSize)
  const _distribution = new Array(numBuckets).fill(0)

  if (distribution) {
    const translationValue = min - globalMin
    let _value, bucketIndex

    for (let i = 0; i < distribution.length; i++) {
      _value = i + translationValue
      bucketIndex = Math.floor(_value / bucketSize)
      _distribution[bucketIndex] += distribution[i]
    }
  }

  return _distribution
}

function calculateDeltaSTD(allStats, dataSet) {
  if (!dataSet.stats) return null
  const { mean } = dataSet.stats
  const { mean: globalMean, std } = allStats
  const delta = (mean - globalMean) / std

  dataSet.stats.deltaStd = (Math.round(delta * 100) / 100).toFixed(2)
}

function calculateCategoryStats(request, Records, DataIntervals) {
  const vector = getRequestedDataVector(request, Records, DataIntervals)

  if (!vector) return

  const stats = calculateStats(vector)

  if (!stats) return null

  return constructProviderDataSet(request, vector, stats, DataIntervals)
}

function calculateData(Records, DataIntervals, request) {
  const categories = Object.keys(Categories)
  // const providers = Object.keys(Providers)
  const providers = request.providers
  const categorySets = []
  let _request

  categories.forEach((category) => {
    let _categorySets = {
      category,
      data: {},
    }

    _request = constructDataSetRequest(request, category)

    const allStats = calculateCategoryStats(_request, Records, DataIntervals)

    _categorySets['data']['ALL'] = allStats

    const globalMin = allStats.stats.min
    const globalMax = allStats.stats.max

    allStats.bucketDistribution = createBucketDistribution(
      allStats.stats,
      globalMin,
      globalMax,
    )

    providers.forEach((provider) => {
      _request = constructDataSetRequestForProvider(request, category, provider)

      const result = calculateCategoryStats(_request, Records, DataIntervals)

      if (result) {
        _categorySets['data'][provider] = result

        result.bucketDistribution = createBucketDistribution(
          result.stats,
          globalMin,
          globalMax,
        )

        calculateDeltaSTD(allStats.stats, result)
      }
    })

    categorySets.push(_categorySets)
  })

  return categorySets
}

let timer = null

export default {
  components: {
    DisplayContainer,
    ComparisonsActions,
    PagesNavigation,
  },
  data() {
    return {
      provider: null,
      esi: null,
      phase: null,
      request: null,
      showPanel: false,
      params: {
        maxIntervalIndex: 0,
        request: {
          range: {
            start: 0,
            end: 0,
          },
          categories: Object.keys(Categories),
          providers: this.setProviders(),
        },
      },
      results: null,
      deltaCutoff: this.setDeltaCutoff(),
    }
  },
  methods: {
    setDeltaCutoff() {
      return this.$store.getters.isMaster ? 0.0 : -2.0
    },
    setProviders() {
      return this.$store.getters.isMaster
        ? Object.keys(Providers)
        : [this.$store.getters.provider]
    },
    intervalRangeSliderHandler(payload) {
      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        const { id, value } = payload
        let _start = +this.params.request.range.start
        let _end = +this.params.request.range.end

        if (id === 'start-range') {
          _start = value
        } else if (id === 'end-range') {
          _end = value
        }

        if (_start >= _end) {
          this.params.request.range.start = _end
          this.params.request.range.end = _start
        } else {
          this.params.request.range.start = _start
          this.params.request.range.end = _end
        }

        const Records = this.$store.getters.records
        const DataIntervals = this.$store.getters.dataIntervals

        this.results = calculateData(
          Records,
          DataIntervals,
          this.params.request,
        )
      }, 100)
    },
    rangeSliderHandler(value) {
      this.deltaCutoff = value
    },
  },
  mounted() {
    const Records = this.$store.getters.records
    const DataIntervals = this.$store.getters.dataIntervals

    if (Records && DataIntervals) {
      const maxIntervalIndex = DataIntervals.length - 1

      this.params.maxIntervalIndex = maxIntervalIndex
      this.params.request.range.end = maxIntervalIndex

      this.results = calculateData(Records, DataIntervals, this.params.request)
    }
  },
}
</script>

<style scoped>
.main {
  display: flex;
  /* gap: clamp(3rem, 1rem + 10vw, 20rem); */
}

nav {
  padding-left: 2rem;
  min-width: 22rem;
  max-width: 25rem;
}

section#stats-ii {
  --default-width: 100rem;
  --default-inset: 4rem;

  padding: 1rem 3rem;
  /* display: flex; */
  /* justify-content: center; */
}

.row {
  display: flex;
  margin-left: 0;
  margin-right: 0;
}

.row + .row {
  margin: 3rem 0;
}

/* .row ~ .row {
  margin: 0 0 3rem 0;
} */

.fadeRow {
  opacity: 0.5;
}

@media (min-width: 100rem) {
  section {
    display: block;
  }

  .row {
    display: block;
  }
}
</style>
