function calculateStats(filteredRecords) {
  // console.log(filteredRecords)
  const vector = removeNullsFromVector(filteredRecords)

  if (vector.length === 0) return null

  const min = Math.min(...vector)
  const max = Math.max(...vector)
  const _distribution = distribution(vector)

  return {
    min,
    max,
    distribution: _distribution,
    mode: mode(_distribution, min),
    mean: +average(vector).toFixed(3),
    std: +standardDeviation(vector).toFixed(3),
    count: vector.length,
    deltaStd: 0,
  }
}

function vectorAdd(v1, v2) {
  if (v1.length !== v2.length) {
    throw new Error('Tried to add two vectors of unequal length! [vectorAdd]')
  }
  return v1.map((data_point1, index) => {
    const data_point2 = v2[index]

    if (data_point1 === null && data_point2 === null) {
      return null
    }
    if (data_point1 === null) {
      return data_point2
    }
    if (data_point2 === null) {
      return data_point1
    }
    return data_point1 + data_point2
  })
}

function vectorProduct(v1, v2) {
  if (v1.length !== v2.length) {
    throw new Error('Tried to add two vectors of unequal length! [vectorAdd]')
  }
  return v1.map((data_point1, index) => {
    const data_point2 = v2[index]
    return Math.round(data_point1 * data_point2)
  })
}

function vectorAddCounter(counters, data_set) {
  if (counters.length !== data_set.length) {
    throw new Error(
      'Tried to add two vectors of unequal length! [vectorAddCounter]',
    )
  }
  return counters.map((counter_val, index) => {
    const data_point = data_set[index]

    if (data_point === null) {
      return counter_val
    }
    return counter_val + 1
  })
}

function vectorAverages(sums, counters) {
  if (counters.length !== data_set.length) {
    throw new Error(
      'Tried to add two vectors of unequal length! [vectorAverages]',
    )
  }
  return counters.map((counter_val, index) => {
    const data_point = sums[index]

    if (data_point === null) {
      return null
    }
    if (counter_val === 0) {
      return null
    }
    if (data_point === 0) {
      return 0
    }
    const avg = data_point / counter_val
    return +avg.toFixed(2)
  })
}

function average(vector) {
  const newVector = removeNullsFromVector(vector)

  if (newVector.length) {
    const sum = vectorSum(newVector)
    const avg = sum / newVector.length
    return +avg.toFixed(3)
  }
  return null
}

function vectorSum(vector) {
  return +vector.reduce((a, b) => a + b)
}

function square(x) {
  return Math.pow(x, 2)
}

function differenceToMean(vector, mean) {
  return vector.map((x) => +(x - mean).toFixed(3))
}

function standardDeviation(vector) {
  const newVector = removeNullsFromVector(vector)
  if (newVector.length === 0) return null
  const mean = average(newVector)
  const squares = newVector.map((x) => square(x - mean))
  const std = Math.sqrt(vectorSum(squares) / newVector.length)
  return +std.toFixed(3)
}

function distribution(dataSet) {
  const minMinutes = Math.min(...dataSet)
  const maxMinutes = Math.max(...dataSet)
  const distributionSize = maxMinutes - minMinutes + 1
  const _distribution = new Array(distributionSize).fill(0)

  for (let minutes of dataSet) {
    const index = minutes - minMinutes
    _distribution[index]++
  }

  return _distribution
}

function mode(_distribution, min) {
  let currentMax = -1
  let maxIndexes = []

  for (let i = 0; i < _distribution.length; i++) {
    let value = _distribution[i]
    if (value > currentMax) {
      currentMax = value
      maxIndexes = [i]
    } else if (value === currentMax) {
      maxIndexes.push(i)
    }
  }

  return {
    count: currentMax,
    indexes: maxIndexes.map((index) => index + min),
  }
}

function removeNullsFromVector(vector) {
  return vector.filter((x) => x !== null && x !== undefined)
}

function calculateMeanVector(data) {
  const vector = []

  for (let provider of data) {
    vector.push(Math.round(provider.stats.mean))
  }

  return vector.sort()
}

export { calculateStats, vectorSum, vectorProduct, average, differenceToMean }
