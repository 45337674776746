<template>
  <div class="container">
    <!-- <div>User ID: {{ userId }}</div> -->
    <ul class="list">
      <li>
        <router-link to="/stats-0" class="nav-link">
          <span>Raw Data</span>
        </router-link>
      </li>
      <li v-if="isMaster">
        <router-link to="/stats-i" class="nav-link">
          <span>Select Data</span>
        </router-link>
      </li>
      <li>
        <router-link to="/stats-ii" class="nav-link">
          <span>Comparisons</span>
        </router-link>
      </li>
      <li>
        <router-link to="/stats-iii" class="nav-link">
          <span>Trends</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    isMaster() {
      return this.$store.getters.isMaster
    },
  },
}
</script>

<style scoped>
/* #page-nav.container {
  background-color: var(--color4);
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  width: 20%;
  width: 40rem;
  animation: enterElement 1s ease;
} */

/* .container {
  margin-bottom: 3rem;
} */

ul {
  margin: 0;
  padding: 0;
}

ul.list {
  list-style-type: none;
  font-size: 1.5rem;
}

li span {
  display: inline-block;
  padding: 1rem 2rem;
  transition: all 0.2s;
}

li:active span {
  transform: scale(0.9);
}

.nav-link {
  display: block;
  text-decoration: none;
  color: var(--color2);
}

.nav-link:hover {
  color: var(--color1);
  cursor: pointer;
}
</style>
