import { createApp } from 'vue'

import App from './App.vue'
import BaseSpinner from './components/BaseSpinner.vue'
import BaseDialog from './components/BaseDialog.vue'
import BaseButton from './components/BaseButton.vue'

import router from './routes.js'
import store from './store/index.js'

const app = createApp(App)

app.component('base-spinner', BaseSpinner)
app.component('base-dialog', BaseDialog)
app.component('base-button', BaseButton)

app.use(store)
app.use(router)

app.mount('#app')
