const sortMean = (a, b) => {
  const _aMean = Math.round(+a.stats.mean)
  const _bMean = Math.round(+b.stats.mean)
  const result = compare(_aMean, _bMean)
  return isEqual(result) ? compareNames(a, b) : result
}

function isEqual(result) {
  return result === 0
}

function compareNames(a, b) {
  let result = compare(lastName(a.provider), lastName(b.provider))
  return isEqual(result)
    ? compare(firstName(a.provider), firstName(b.provider))
    : result
}

function firstName(name) {
  const _name = name.split(' ')
  return _name[0]
}

function lastName(name) {
  const _name = name.split(' ')
  return _name[1]
}

const sortDataIntervals = (a, b) => {
  const _aYear = a[0]
  const _bYear = b[0]
  const _aInterval = a[1]
  const _bInterval = b[1]
  const result = compare(_aYear, _bYear)
  return isEqual(result) ? compare(_aInterval, _bInterval) : result
}

function compare(_a, _b) {
  if (_a < _b) return -1
  if (_a > _b) return 1
  return 0
}

export { sortMean, sortDataIntervals }
