<template>
  <div class="stats-home">
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <div id="page-nav">
      <div v-if="isLoading">
        <base-spinner></base-spinner>
      </div>
      <pages-navigation v-else></pages-navigation>
    </div>
  </div>
</template>

<script>
import PagesNavigation from './PagesNavigation.vue'

export default {
  components: { PagesNavigation },

  data() {
    return {
      isLoading: false,
      error: null,
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userId
    },
  },
  methods: {
    handleError() {
      this.error = null
    },
  },
  // async mounted() {
  //   if (this.$store.getters.data === null) {
  //     this.isLoading = true

  //     try {
  //       await this.$store.dispatch('loadData')
  //       this.$store.dispatch('constructRecordSet')
  //       this.$store.dispatch('constructDataIntervals')
  //     } catch (err) {
  //       this.error = 'Error message: ' + (err.message || 'Failed to load data.')
  //       console.log(this.error)
  //       // this.$store.dispatch('autoLogout')
  //     }

  //     this.isLoading = false
  //   }
  // },
}
</script>

<style scoped>
.stats-home {
  display: flex;
  align-items: center;
  justify-content: center;
}

#page-nav {
  background-color: var(--color4);
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  width: 20%;
  width: 40rem;
  animation: enterElement 1s ease;
}
</style>
