<template>
  <div class="not-found">
    Not Found
  </div>
</template>

<style scoped>
.not-found {
  display: flex;
  justify-content: center;
}
</style>
