<template>
  <div>
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <div class="signup-form">
      <div class="container">
        <h1>Sign Up</h1>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <form id="signup-form" @submit.prevent="submitForm" v-else>
          <div class="form-control">
            <input
              type="text"
              required
              name="email"
              id="email"
              v-model.trim="email"
              autocomplete="email"
              autofocus
            />
            <label for="email">Email</label>
          </div>
          <div class="form-control">
            <input
              type="password"
              name="new-password"
              id="password"
              v-model.trim="password"
              required
              minlength="6"
            />
            <label for="password">Password</label>
          </div>
          <p v-if="!formIsValid" class="error">
            Please enter a valid email and password (must be at least six
            characters long.)
          </p>
          <button class="btn">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
function addTransitionDelays() {
  const labels = document.querySelectorAll(`.form-control label`)

  labels.forEach((label) => {
    label.innerHTML = label.innerText
      .split('')
      .map(
        (letter, idx) =>
          `<span style="transition-delay:${idx * 50}ms;">${letter}</span>`,
      )
      .join('')
  })
}

function setFocus() {
  document.getElementById('email').focus()
}

export default {
  data() {
    return {
      email: '',
      password: '',
      formIsValid: true,
      isLoading: false,
      error: null,
    }
  },
  methods: {
    async submitForm() {
      this.formIsValid = true
      if (
        this.email === '' ||
        !this.email.includes('@') ||
        this.password.length < 6
      ) {
        this.formIsValid = false
        return
      }

      this.isLoading = true

      try {
        await this.$store.dispatch('signup', {
          email: this.email,
          password: this.password,
        })
        this.$router.replace('/home')
      } catch (err) {
        this.error = 'Error message: ' + (err.message || 'Sign up failed.')
        this.isLoading = false
      }
    },
    handleError() {
      this.error = null
      this.password = ''
      addTransitionDelays()
      setFocus()
    },
  },
  mounted() {
    addTransitionDelays()
  },
}
</script>

<style>
.signup-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-form .container {
  background-color: var(--color4);
  padding: 2rem 4rem;
  border-radius: 0.5rem;
  animation: enterElement 1s ease;
  width: 40rem;
}

.signup-form .container h1 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3rem;
}

#signup-form p.error {
  margin: 3rem 0;
}

#signup-form .form-control {
  position: relative;
  margin: 2rem 0 4rem;
  width: 30rem;
}

#signup-form .form-control input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px var(--color1) solid;
  display: block;
  width: 100%;
  padding: 1.5rem 0;
  font-size: 1.8rem;
  color: var(--color1);
}

#signup-form .form-control input:focus,
#signup-form .form-control input:valid {
  outline: 0;
  border-bottom-color: var(--color2);
}

#signup-form .form-control label {
  position: absolute;
  top: 1.5rem;
  left: 0;
}

#signup-form .form-control label span {
  display: inline-block;
  font-size: 1.8rem;
  min-width: 0.5rem;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

#signup-form .form-control input:focus + label span,
#signup-form .form-control input:valid + label span,
#signup-form .form-control input.error + label span {
  color: var(--color2);
  transform: translateY(-3rem);
}

/* Change autocomplete styles in WebKit */
#signup-form input:-webkit-autofill,
#signup-form input:-webkit-autofill:hover,
#signup-form input:-webkit-autofill:focus,
#signup-form textarea:-webkit-autofill,
#signup-form textarea:-webkit-autofill:hover,
#signup-form textarea:-webkit-autofill:focus,
#signup-form select:-webkit-autofill,
#signup-form select:-webkit-autofill:hover,
#signup-form select:-webkit-autofill:focus {
  /* border: 1px solid var(--color3); */
  border-bottom-color: var(--color2);
  -webkit-text-fill-color: var(--color1);
  -webkit-box-shadow: 0 0 0px 1000px var(--color4) inset;
  box-shadow: 0 0 0px 1000px var(--color4) inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
