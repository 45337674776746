<template>
  <div class="actions" id="actions">
    <div class="range-container delta-std" v-if="$store.getters.isMaster">
      <span>&#916;Std</span>
      <input
        type="number"
        id="rangeNumber"
        min="0.0"
        max="2.0"
        :value="deltaCutoff"
        step="0.1"
        @input="rangeSliderHandler"
      />
      <input
        type="range"
        id="delta-std-range"
        min="0.0"
        max="2.0"
        :value="deltaCutoff"
        step="0.1"
        @input="rangeSliderHandler"
      />
    </div>
    <div class="interval range-container">
      <span>Date Range</span>
      <input
        type="range"
        id="start-range"
        min="0"
        :max="maxIntervalIndex"
        :value="start"
        @input="intervalRangeSliderHandler"
      />
      <input
        type="range"
        id="end-range"
        min="0"
        :max="maxIntervalIndex"
        :value="end"
        @input="intervalRangeSliderHandler"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ['deltaCutoff', 'start', 'end', 'maxIntervalIndex'],
  methods: {
    rangeSliderHandler(e) {
      const value = +e.target.value
      this.$emit('range-slider-handler', value)
    },
    intervalRangeSliderHandler(e) {
      const id = e.target.id
      const value = +e.target.value
      this.$emit('interval-range-slider-handler', {
        id,
        value,
      })
    },
  },
}
</script>

<style scoped>
.actions {
  width: 74rem;
  display: flex;
  justify-content: space-between;
  padding: 0 5rem;
}

.range-container {
  display: flex;
  align-items: center;
}

.range-container span {
  margin-right: 1.5rem;
}

.range-container.interval input {
  margin-right: 1rem;
}

@media (min-width: 70rem) {
  .actions {
    width: 96rem;
  }
}

@media (min-width: 100rem) {
  .actions {
    width: 100%;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  margin-right: 2rem;
  background-color: var(--color3);
  border: 0;
  padding: 0.3rem 0.8rem;
  font-size: 1.8rem;
  color: var(--color1);
  width: 6rem;
}

input[type='range'] {
  width: 100px;
  margin: 18px 0;
  -webkit-appearance: none;
  background: var(--color5);
}

input:focus,
input:valid {
  outline: none;
}

/* input[type='range'] + label {
  background-color: var(--color1);
  color: var(--color5);
  position: absolute;
  top: -25px;
  left: -30px;
  width: 80px;
  padding: 5px 0;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
} */

/* Chrome and Safari */

input[type='range']::-webkit-slider-runnable-track {
  background: var(--color3);
  border-radius: 4px;
  width: 100%;
  height: 10px;
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: white;
  border-radius: 50%;
  border: 1px solid var(--color3);
  margin-top: -7px;
  cursor: pointer;
}

/* Firefox */

input[type='range']::-moz-range-track {
  background: var(--color3);
  border-radius: 4px;
  width: 100%;
  height: 13px;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: white;
  border-radius: 50%;
  border: 1px solid var(--color3);
  margin-top: -7px;
  cursor: pointer;
}

/* IE */

input[type='range']::-ms-track {
  background: var(--color3);
  border-radius: 4px;
  width: 100%;
  height: 13px;
  cursor: pointer;
}

input[type='range']::-ms-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: white;
  border-radius: 50%;
  border: 1px solid var(--color3);
  margin-top: -7px;
  cursor: pointer;
}
</style>
