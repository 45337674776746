<template>
  <div class="main">
    <nav id="nav" class="nav">
      <pages-navigation></pages-navigation>
      <div class="spacer"></div>
      <provider-list
        @selected-provider="selectProvider"
        v-if="showProviders"
      ></provider-list>
    </nav>
    <section id="section" class="section" v-if="provider">
      <trend-container
        v-for="(dataSet, index) in dataSets"
        :dataSet="dataSet"
        :summarySet="summaryDataSets[dataSet.category]"
        :key="index"
        :scale_factor="scale_factor"
        :provider="provider"
      ></trend-container>
    </section>
  </div>
</template>

<script>
import TrendContainer from './TrendContainer.vue'
import ProviderList from './ProviderList.vue'
import { Providers, Categories, Indexes } from '../scripts/global-data.mjs'

import {
  initProviderDataSet,
  initCategoryDataSet,
  getVectorIndex,
  createPathElementDefinition,
  computeTrandlineCommands,
  initSummaryDataSet,
  constructSummarySet,
  createPathElementStatement,
  numberOfMonthsInDataSet,
  constructHorizontalLineCommand,
} from '../scripts/functions.js'
import { calculateStats } from '../scripts/stats-functions.mjs'
import PagesNavigation from './PagesNavigation.vue'

const X_TICK_SIZE = 20
const Y_TICK_SIZE = 50
const NUM_DATA_MONTHS = numberOfMonthsInDataSet()
const DOMAIN_SIZE = NUM_DATA_MONTHS * 2
const DOMAIN_WIDTH = DOMAIN_SIZE * X_TICK_SIZE

export default {
  components: { ProviderList, TrendContainer, PagesNavigation },
  data() {
    return {
      provider: null,
      providerDataSets: Object.create(null),
      summaryDataSets: Object.create(null),
      scale_factor: 1,
      dataSets: [],
      showProviders: false,
    }
  },
  methods: {
    displayData() {
      Object.keys(Categories).forEach((category) => {
        const y_tick_size = Categories[category].yTickSize
        this.scale_factor = Math.round(Y_TICK_SIZE / y_tick_size)
      })
    },
    selectProvider(provider) {
      this.provider = provider

      const Records = this.$store.getters.records

      if (!Records) {
        this.$store.dispatch('autoLogout')
        return
      }

      const keys = Object.keys(this.providerDataSets[provider].categoryDataSets)

      const dataSets = keys.map((category) => {
        return {
          category,
          data: this.providerDataSets[provider].categoryDataSets[category],
        }
      })

      this.dataSets = dataSets
    },
    initCategoryDataSets() {
      Object.keys(Providers).forEach((provider) => {
        this.providerDataSets[provider] = initProviderDataSet()

        Object.keys(Categories).forEach((category) => {
          this.providerDataSets[provider].categoryDataSets[
            category
          ] = initCategoryDataSet()
        })
      })
    },
    constructCategoryVectors() {
      const Records = this.$store.getters.records

      // place all data points in their category vectors
      for (let record of Records) {
        // find the provider
        const provider = record[Indexes.PROVIDER]
        const providerDataSet = this.providerDataSets[provider]
        // set the provider type in the final data set
        providerDataSet.providerType = record[Indexes.PROVIDER_TYPE]

        Object.keys(Categories).forEach((category) => {
          const categoryDataSet = providerDataSet.categoryDataSets[category]

          const year = record[Indexes.YEAR]
          const month = record[Indexes.MONTH]
          const day = record[Indexes.DAY]
          // const interval = record[Indexes.INTERVAL] ***** DO NOT DELETE *****
          const dataPoint = record[Indexes[category]]

          const index = getVectorIndex(year, month, day)

          // dataSet.data.push([year, month, day, interval, dataPoint]) ***** DO NOT DELETE *****
          categoryDataSet.vector[index] = dataPoint
        })
      }
    },
    constructCategoryStats() {
      Object.keys(Providers).forEach((provider) => {
        const categoryDataSets = this.providerDataSets[provider]
          .categoryDataSets

        Object.keys(Categories).forEach((category) => {
          const vector = categoryDataSets[category].vector
          categoryDataSets[category].stats = calculateStats(vector)
        })
      })
    },
    constructCategoryPath() {
      Object.keys(Providers).forEach((provider) => {
        const categoryDataSets = this.providerDataSets[provider]
          .categoryDataSets

        Object.keys(Categories).forEach((category) => {
          const y_tick_size = Categories[category].yTickSize
          this.scale_factor = Math.round(Y_TICK_SIZE / y_tick_size)
          const vector = categoryDataSets[category].vector
          const path = createPathElementDefinition(vector)
          categoryDataSets[category].path = path
        })
      })
    },
    constructCategoryTrendlines() {
      Object.keys(Providers).forEach((provider) => {
        const categoryDataSets = this.providerDataSets[provider]
          .categoryDataSets

        Object.keys(Categories).forEach((category) => {
          const y_tick_size = Categories[category].yTickSize
          this.scale_factor = Math.round(Y_TICK_SIZE / y_tick_size)
          const vector = categoryDataSets[category].vector
          const trendline = computeTrandlineCommands(vector)
          categoryDataSets[category].trendline = trendline
        })
      })
    },
    initSummaryDataSets() {
      Object.keys(Categories).forEach((category) => {
        this.summaryDataSets[category] = initSummaryDataSet()
      })
    },
    constructSummaryStats() {
      const Records = this.$store.getters.records
      const DataIntervals = this.$store.getters.dataIntervals

      Object.keys(Categories).forEach((category) => {
        const request = { ...Categories[category] }
        this.summaryDataSets[category].stats = constructSummarySet(
          request,
          Records,
          DataIntervals,
        ).stats
      })
    },
    constructSummaryMeanlines() {
      Object.keys(Categories).forEach((category) => {
        const y_tick_size = Categories[category].yTickSize
        this.scale_factor = Math.round(Y_TICK_SIZE / y_tick_size)

        const commands = []
        const { mean } = this.summaryDataSets[category].stats
        commands.push(
          createPathElementStatement(
            'M',
            0,
            Math.round(mean) * this.scale_factor,
          ),
        )
        commands.push(createPathElementStatement('H', DOMAIN_WIDTH))
        this.summaryDataSets[category].meanline = commands.join('\n')
      })
    },
    constructSummarySTDlines() {
      Object.keys(Categories).forEach((category) => {
        const y_tick_size = Categories[category].yTickSize
        this.scale_factor = Math.round(Y_TICK_SIZE / y_tick_size)
        const { mean, std } = this.summaryDataSets[category].stats
        const posStd = Math.round(mean + std) * this.scale_factor
        const negStd = Math.round(mean - std) * this.scale_factor
        this.summaryDataSets[
          category
        ].posStdLine = constructHorizontalLineCommand(posStd)
        this.summaryDataSets[
          category
        ].negStdLine = constructHorizontalLineCommand(negStd)
      })
    },
    constructSummaryCategoryVectors() {
      const Records = this.$store.getters.intervalAverages

      // console.log(Records)
      const intervals = Object.keys(Records)

      // console.log(intervals)

      for (let interval of intervals) {
        const data = Records[interval]
        const _interval = interval.split(' ')
        const __interval = _interval[0].split('-')
        const month = +__interval[0]
        const day = +__interval[1]
        const year = +_interval[2]
        const index = getVectorIndex(year, month, day)

        Object.keys(Categories).forEach((category) => {
          const categoryDataSet = this.summaryDataSets[category]
          const dataPoint = data[category].avg
          categoryDataSet.vector[index] = dataPoint
        })
      }
    },
    constructSummaryCategoryPath() {
      Object.keys(Categories).forEach((category) => {
        const y_tick_size = Categories[category].yTickSize
        this.scale_factor = Math.round(Y_TICK_SIZE / y_tick_size)
        const vector = this.summaryDataSets[category].vector
        const path = createPathElementDefinition(vector)
        this.summaryDataSets[category].path = path
      })
    },
    constructSummaryCategoryTrendline() {
      Object.keys(Categories).forEach((category) => {
        const y_tick_size = Categories[category].yTickSize
        this.scale_factor = Math.round(Y_TICK_SIZE / y_tick_size)
        const vector = this.summaryDataSets[category].vector
        const trendline = computeTrandlineCommands(vector)
        this.summaryDataSets[category].trendline = trendline
      })
    },
  },
  mounted() {
    // construct the final data set
    this.initCategoryDataSets()
    this.constructCategoryVectors()
    this.constructCategoryStats()

    // construct the data structures necessary for displaying the data
    this.constructCategoryPath()
    this.constructCategoryTrendlines()

    // data
    this.initSummaryDataSets()
    this.constructSummaryStats()
    this.constructSummaryCategoryVectors()
    this.constructSummaryCategoryPath()
    this.constructSummaryCategoryTrendline()

    // data necesary for display
    this.constructSummaryMeanlines()
    this.constructSummarySTDlines()

    if (this.$store.getters.isMaster) {
      this.showProviders = true
    } else {
      this.selectProvider(this.$store.getters.provider)
    }

    // console.log(this.summaryDataSets)
  },
}
</script>

<style scoped>
.spacer {
  height: 2rem;
}

.main {
  display: flex;
  /* gap: clamp(3rem, 1rem + 10vw, 20rem); */
  gap: 3rem;
}

nav {
  padding-left: 2rem;
  min-width: 22rem;
  max-width: 25rem;
}

.data-container {
  display: block;
}

.data-container h4 {
  margin-bottom: 2rem;
}

svg.chart {
  width: calc(
    var(--chart-width) + var(--chart-padding-left) + var(--chart-padding-right)
  );
  height: calc(
    var(--chart-height) + var(--chart-padding-top) + var(--chart-padding-bottom)
  );
}

svg.chart g.grid line {
  stroke: black;
  stroke-width: 1;
  opacity: 0.1;
}

svg.chart g.grid line:first-of-type {
  stroke-width: 1;
  opacity: 0.3;
}

svg.chart .labels {
  font-family: Arial;
  font-size: 1.4rem;
  kerning: 1;
}

svg.chart .labels.x-labels {
  text-anchor: middle;
}

svg.chart .labels.y-labels {
  text-anchor: end;
}
</style>
