import { Indexes } from './global-data.mjs'

import {
  average,
  differenceToMean,
  vectorProduct,
  vectorSum,
  calculateStats,
} from './stats-functions.mjs'

const CHART_HEIGHT = 400
const LEFT = 80
const TOP = 50
const X_TICK_SIZE = 20
let scale_factor = 1

const NUM_DATA_MONTHS = numberOfMonthsInDataSet()
const DOMAIN_SIZE = NUM_DATA_MONTHS * 2
const DOMAIN_WIDTH = DOMAIN_SIZE * X_TICK_SIZE

function numberOfMonthsInDataSet() {
  const currentDate = new Date()
  const cMonth = currentDate.getMonth() + 1
  const cYear = currentDate.getFullYear()
  const fMonth = 6
  const fYear = 2020
  return 12 - fMonth + (cYear - fYear - 1) * 12 + cMonth
}

function initProviderDataSet() {
  const providerDataSet = Object.create(null)
  providerDataSet.providerType = null
  providerDataSet.categoryDataSets = Object.create(null)
  return providerDataSet
}

function initSummaryDataSet() {
  const _set = Object.create(null)
  _set.stats = null
  _set.meanline = null
  _set.posStdLine = null
  _set.negStdLine = null
  _set.vector = initVector()
  _set.path = null
  _set.trendline = null
  return _set
}

function initCategoryDataSet() {
  const categoryDataSet = Object.create(null)
  // categoryDataSet.data = []
  categoryDataSet.vector = initVector()
  categoryDataSet.stats = Object.create(null)
  categoryDataSet.path = null
  categoryDataSet.trendline = null
  return categoryDataSet
}

function initVector() {
  // all providers will have the same set of possible data points
  return new Array(DOMAIN_SIZE).fill(null)
}

function getVectorIndex(year, month, day) {
  // find the position in the vector that corresponds to the record timestamp
  let index = ((year - 2020) * 12 + month - 6) * 2
  return day === 1 ? index : ++index
}

function createPathElementDefinition(vector) {
  const commands = []
  let isBreak = true
  let cmd, statement, value

  for (let index = 0; index < vector.length; index++) {
    value = vector[index]

    if (value === null || value === undefined) {
      isBreak = true
      continue
    }

    cmd = isBreak ? 'M' : 'L'
    statement = createPathElementStatement(
      cmd,
      index * X_TICK_SIZE,
      value * scale_factor,
    )
    commands.push(statement)
    isBreak = false
  }

  return commands.join('\n')
}

function createPathElementStatement(cmd, x, y) {
  if (y) {
    return `${cmd} ${LEFT + x} ${TOP + (CHART_HEIGHT - y)}`
  } else {
    return `${cmd} ${LEFT + x}`
  }
}

function computeTrandlineCommands(vector) {
  if (vector.length <= 1) return null

  const xValues = []
  const yValues = []

  for (let index = 0; index < vector.length; index++) {
    const value = vector[index]

    if (value === null || value === undefined) {
      continue
    }

    xValues.push(index)
    yValues.push(value)
  }

  const xMean = average(xValues)
  const yMean = average(yValues)

  const xDiffs = differenceToMean(xValues, xMean)
  const yDiffs = differenceToMean(yValues, yMean)

  const xDiffs_yDiffs = vectorProduct(xDiffs, yDiffs)
  const xDiffs_xDiffs = vectorProduct(xDiffs, xDiffs)

  const xDiffs_yDiffs_Sum = vectorSum(xDiffs_yDiffs)
  const xDiffs_xDiffs_Sum = vectorSum(xDiffs_xDiffs)

  let slope = +(xDiffs_yDiffs_Sum / xDiffs_xDiffs_Sum).toFixed(2)
  let b = null,
    trendline = null

  if (isNaN(slope)) {
    slope = null
  } else {
    b = Math.round(yMean - slope * xMean)
    const m = (x) => Math.round(slope * x)
    const x1 = 0
    const y1 = m(x1) + b

    const x2 = xValues[xValues.length - 1]
    const y2 = m(x2) + b

    const commands = [
      createPathElementStatement('M', x1 * X_TICK_SIZE, y1 * scale_factor),
      createPathElementStatement('L', x2 * X_TICK_SIZE, y2 * scale_factor),
    ]
    trendline = commands.join('\n')
  }

  return {
    xMean,
    yMean,
    xDiffs,
    yDiffs,
    xDiffs_yDiffs,
    xDiffs_xDiffs,
    xDiffs_yDiffs_Sum,
    xDiffs_xDiffs_Sum,
    slope,
    yIntercept: b,
    path: trendline,
  }
}

function constructSummarySet(request, Records, DataIntervals) {
  const vector = getRequestedDataVector(request, Records, DataIntervals)
  const stats = calculateStats(vector)
  return constructProviderDataSet(request, vector, stats)
}

function getRequestedDataVector(request, Records, DataIntervals) {
  const vector = []
  const { provider, esi, phase, range } = request
  const catIndex = `esi${esi}_${phase}`
  const dataSetIndex = Indexes[catIndex]

  for (let record of Records) {
    const alpha = isSelectedProvider(provider, record)
    const gamma = isAfterEqualStart(range, record, DataIntervals)
    const delta = isBeforeEqualEnd(range, record, DataIntervals)

    if (!alpha) {
      continue
    }
    if (!gamma) {
      continue
    }
    if (!delta) {
      continue
    }

    const dataPoint = record[dataSetIndex]
    vector.push(dataPoint)
  }

  return vector
}

function isAfterEqualStart(range, record, DataIntervals) {
  if (!range) return true

  const { start } = range
  if (isNaN(parseInt(start))) return true

  const [year, interval] = DataIntervals[start]

  const _interval = interval.split('-')
  const [month, day] = _interval[0].split('/')

  const record_year = record[Indexes.YEAR]
  const record_month = record[Indexes.MONTH]
  const record_day = record[Indexes.DAY]

  if (record_year < year) return false
  if (record_year > year) return true
  if (record_month < month) return false
  if (record_month > month) return true
  if (record_day < day) return false
  return true
}

function isBeforeEqualEnd(range, record, DataIntervals) {
  if (!range) return true
  const { end } = range
  if (isNaN(parseInt(end))) return true

  const [year, interval] = DataIntervals[end]

  const _interval = interval.split('-')
  const [month, day] = _interval[0].split('/')

  const record_year = record[Indexes.YEAR]
  const record_month = record[Indexes.MONTH]
  const record_day = record[Indexes.DAY]

  if (record_year > year) return false
  if (record_year < year) return true
  if (record_month > month) return false
  if (record_month < month) return true
  if (record_day > day) return false
  return true
}

function isSelectedProvider(provider, record) {
  if (!provider) return true
  const record_name = record[Indexes.PROVIDER]
  return provider === record_name
}

// function isSelectedYears(years, record) {
//   if (!years) return true
//   const record_year = record[Indexes.YEAR]
//   const selectedYears = new Set(years.length === 1 ? years : Years)
//   return selectedYears.has(record_year)
// }

function constructProviderDataSet(request, vector, stats) {
  const { provider, provider_id, esi, phase, target, years } = request

  return {
    id: provider_id ? provider_id : 0, // what is this for?  For possible panel removal operation?
    provider: providerPretty(provider),
    esi,
    phase: phase.toUpperCase(),
    target, // what is this for? The target value that the providers are trying to meet.
    years: yearsPretty(years),
    data: vector,
    stats,
    request,
  }
}

function providerPretty(provider) {
  return provider ? provider : 'ALL'
}

function yearsPretty(years = []) {
  return years.length ? years.join(', ') : 'ALL'
}

function constructHorizontalLineCommand(y1) {
  const x1 = 0
  const x2 = DOMAIN_WIDTH
  const commands = []
  commands.push(createPathElementStatement('M', x1, y1))
  commands.push(createPathElementStatement('H', x2))
  return commands.join('\n')
}

function generateID() {
  return Math.floor(Math.random() * 100000000)
}

export {
  initProviderDataSet,
  initCategoryDataSet,
  getVectorIndex,
  createPathElementDefinition,
  computeTrandlineCommands,
  initSummaryDataSet,
  constructSummarySet,
  createPathElementStatement,
  numberOfMonthsInDataSet,
  constructHorizontalLineCommand,
  generateID,
}
