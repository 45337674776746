<template>
  <section id="section" class="section">
    <table class="data-table" v-if="provider">
      <thead>
        <tr>
          <th colspan="2">{{ provider }}</th>
          <th colspan="2">ESI 3 (&lt; 150)</th>
          <th colspan="2">ESI 4 (&lt; 90)</th>
          <th colspan="2">ESI 5 (&lt; 60)</th>
          <th></th>
        </tr>
        <tr>
          <th colspan="2"></th>
          <th>A-D</th>
          <th>IB-CC</th>
          <th>A-D</th>
          <th>IB-CC</th>
          <th>A-D</th>
          <th>IB-CC</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(record, index) in records"
          :key="index"
          :class="{ dark: Math.floor(index / setSize) % 2 !== 0 }"
        >
          <td>{{ cell(record, 0) }}</td>
          <td>{{ cell(record, 1) }}</td>
          <td :class="{ [cellClass(record, 2)]: true }">
            <span v-html="cellValue(record, 2)"></span>
          </td>
          <td :class="{ [cellClass(record, 3)]: true }">
            <span v-html="cellValue(record, 3)"></span>
          </td>
          <td :class="{ [cellClass(record, 4)]: true }">
            <span v-html="cellValue(record, 4)"></span>
          </td>
          <td :class="{ [cellClass(record, 5)]: true }">
            <span v-html="cellValue(record, 5)"></span>
          </td>
          <td :class="{ [cellClass(record, 6)]: true }">
            <span v-html="cellValue(record, 6)"></span>
          </td>
          <td :class="{ [cellClass(record, 7)]: true }">
            <span v-html="cellValue(record, 7)"></span>
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  props: ['provider', 'records'],
  data() {
    return {
      setSize: 2,
    }
  },
  methods: {
    cell(record, index) {
      return record[index]
    },
    cellClass(record, index) {
      return record[index]._class
    },
    cellValue(record, index) {
      return record[index]._char
    },
  },
}
</script>

<style scoped>
section {
  min-width: 72rem;
  font-size: 1.5rem;
  margin-bottom: 3rem;
}

.data-table {
  border-collapse: collapse;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  animation: enterElement 0.8s ease;
}

.data-table thead th {
  background-color: var(--color3);
  color: var(--color1);
}

.data-table td,
.data-table th {
  padding: 1rem 2rem;
}

.data-table td {
  text-align: right;
}
.data-table td:first-of-type {
  width: 7rem;
  padding-right: 0;
}
.data-table td:last-of-type {
  width: 4rem;
}
.data-table td.center {
  padding: 0 2.5rem;
}

.data-table tbody tr {
  background-color: var(--color4);
}
.data-table tbody tr.dark {
  background-color: var(--color3);
}
.data-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--color2);
}

.data-table caption {
  font-size: 2rem;
  margin-bottom: 2rem;
}

@keyframes enterElement {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
</style>
