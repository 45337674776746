<template>
  <div class="display-container">
    <div class="data-container">
      <h4>
        <span>ESI {{ esi }} {{ phase }}</span>
        <span>{{ provider }}</span>
      </h4>
      <div class="panel chart-container">
        <div class="chart-container-label overall">
          All Providers
        </div>

        <div class="chart-container-label provider">
          Selected Provider
        </div>
        <div class="chart-y-axis-label">
          Time (minutes)
        </div>
        <svg class="chart">
          <circle
            v-for="(circle, index) in circles"
            :key="index"
            :cx="circle.cx"
            :cy="circle.cy"
            :r="circle.r"
            :stroke="circle.stroke"
            :fill="circle.stroke"
          ></circle>
          <g class="grid xgrid">
            <line
              v-for="(xline, index) in xlines"
              :key="index"
              :x1="xline.x1"
              :y1="xline.y1"
              :x2="xline.x2"
              :y2="xline.y2"
            ></line>
          </g>
          <g class="grid ygrid">
            <line
              v-for="(yline, index) in ylines"
              :key="index"
              :x1="yline.x1"
              :y1="yline.y1"
              :x2="yline.x2"
              :y2="yline.y2"
            ></line>
          </g>
          <g class="labels x-labels">
            <text
              v-for="(label, index) in xlabels"
              :key="index"
              :x="label.x"
              :y="label.y"
            >
              {{ label.data }}
            </text>
          </g>
          <g class="labels y-labels">
            <text
              v-for="(label, index) in ylabels"
              :key="index"
              :x="label.x"
              :y="label.y"
            >
              {{ label.data }}
            </text>
          </g>
          <path
            v-if="mainline"
            :fill="mainline.fill"
            :stroke="mainline.stroke"
            :stroke-width="mainline.strokeWidth"
            :d="mainline.d"
          ></path>
          <path
            v-if="trendline"
            :fill="trendline.fill"
            :stroke="trendline.stroke"
            :stroke-width="trendline.strokeWidth"
            :d="trendline.d"
            :stroke-dasharray="trendline.strokeDasharray"
            :stroke-opacity="trendline.strokeOpacity"
          ></path>
          <path
            v-if="meanline"
            :fill="meanline.fill"
            :stroke="meanline.stroke"
            :stroke-width="meanline.strokeWidth"
            :d="meanline.d"
            :stroke-dasharray="meanline.strokeDasharray"
            :stroke-opacity="meanline.strokeOpacity"
          ></path>
          <path
            v-if="posStdLine"
            :fill="posStdLine.fill"
            :stroke="posStdLine.stroke"
            :stroke-width="posStdLine.strokeWidth"
            :d="posStdLine.d"
            :stroke-dasharray="posStdLine.strokeDasharray"
            :stroke-opacity="posStdLine.strokeOpacity"
          ></path>
          <path
            v-if="negStdLine"
            :fill="negStdLine.fill"
            :stroke="negStdLine.stroke"
            :stroke-width="negStdLine.strokeWidth"
            :d="negStdLine.d"
            :stroke-dasharray="negStdLine.strokeDasharray"
            :stroke-opacity="negStdLine.strokeOpacity"
          ></path>
          <path
            v-if="summary.meanline"
            :fill="summary.meanline.fill"
            :stroke="summary.meanline.stroke"
            :stroke-width="summary.meanline.strokeWidth"
            :d="summary.meanline.d"
            :stroke-dasharray="summary.meanline.strokeDasharray"
            :stroke-opacity="summary.meanline.strokeOpacity"
          ></path>
          <path
            v-if="summary.posStdLine"
            :fill="summary.posStdLine.fill"
            :stroke="summary.posStdLine.stroke"
            :stroke-width="summary.posStdLine.strokeWidth"
            :d="summary.posStdLine.d"
            :stroke-dasharray="summary.posStdLine.strokeDasharray"
            :stroke-opacity="summary.posStdLine.strokeOpacity"
          ></path>
          <path
            v-if="summary.negStdLine"
            :fill="summary.negStdLine.fill"
            :stroke="summary.negStdLine.stroke"
            :stroke-width="summary.negStdLine.strokeWidth"
            :d="summary.negStdLine.d"
            :stroke-dasharray="summary.negStdLine.strokeDasharray"
            :stroke-opacity="summary.negStdLine.strokeOpacity"
          ></path>
          <path
            v-if="summary.trendline"
            :fill="summary.trendline.fill"
            :stroke="summary.trendline.stroke"
            :stroke-width="summary.trendline.strokeWidth"
            :d="summary.trendline.d"
            :stroke-dasharray="summary.trendline.strokeDasharray"
            :stroke-opacity="summary.trendline.strokeOpacity"
          ></path>
          <path
            v-if="summary.mainline"
            :fill="summary.mainline.fill"
            :stroke="summary.mainline.stroke"
            :stroke-width="summary.mainline.strokeWidth"
            :d="summary.mainline.d"
            :stroke-dasharray="summary.mainline.strokeDasharray"
            :stroke-opacity="summary.mainline.strokeOpacity"
          ></path>
          <circle
            v-for="(circle, index) in summary.circles"
            :key="index"
            :cx="circle.cx"
            :cy="circle.cy"
            :r="circle.r"
            :stroke="circle.stroke"
            :fill="circle.stroke"
          ></circle>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { numberOfMonthsInDataSet } from '../scripts/functions.js'
import { Categories } from '../scripts/global-data.mjs'

const CHART_HEIGHT = 400
const X_TICK_SIZE = 20
const Y_TICK_SIZE = 50
const TOP = 50
const LEFT = 80
const NUM_GRID_LINES_Y = 8
const NUM_DATA_MONTHS = numberOfMonthsInDataSet()
const DOMAIN_SIZE = NUM_DATA_MONTHS * 2
const DOMAIN_WIDTH = DOMAIN_SIZE * X_TICK_SIZE
const COLOR1 = 'orange'
const COLOR2 = '#0074d9'
const COLOR3 = 'black'
const OPACITY1 = '0.4'
const POINT_RADIUS = 3
const DEFAULT_DASHED_LINE = '5,5'

function createLineElementDOM(point1, point2) {
  const lineEl = {
    x1: +LEFT + point1[0],
    y1: +TOP + point1[1],
    x2: +LEFT + point2[0],
    y2: +TOP + point2[1],
  }

  return lineEl
}

function createPoints(vector, color, scale_factor) {
  const pointElements = []

  for (let index = 0; index < vector.length; index++) {
    const dataPoint = vector[index]
    if (dataPoint === null || dataPoint === undefined) continue
    // if (isPointIsolated(vector, index)) {
    pointElements.push(createPoint(index, dataPoint, color, scale_factor))
    // }
  }

  return pointElements
}

// function isPointIsolated(vector, index) {
//   const prevValue = getVectorValue(index - 1, vector)
//   const nextValue = getVectorValue(index + 1, vector)
//   return prevValue === null && nextValue === null
// }

// function getVectorValue(index, vector) {
//   return isValidIndex(index, vector.length) ? vector[index] : null
// }

// function isValidIndex(index, max) {
//   return index >= 0 && index < max
// }

function createPoint(index, value, color, scale_factor) {
  const cx = LEFT + index * X_TICK_SIZE
  const cy = TOP + (CHART_HEIGHT - value) + scale_factor
  const r = POINT_RADIUS
  const stroke = color

  const pointEl = {
    cx,
    cy,
    r,
    stroke,
  }
  return pointEl
}

function addToSVG(definition, params) {
  if (definition) {
    const _definition = definition.replace('\n', ' ')
    return createPathElement(_definition, params)
  }

  return null
}

function createPathElement(definition, params) {
  const {
    color = COLOR2,
    useDashedLine = false,
    lineType = DEFAULT_DASHED_LINE,
    opacity,
  } = params

  const pEl = {
    fill: 'none',
    stroke: color,
    strokeWidth: '3',
    d: definition,
  }

  if (useDashedLine) pEl.strokeDasharray = lineType
  if (opacity) pEl.strokeOpacity = opacity

  return pEl
}

function createLabel(point, text) {
  const labelEl = {
    x: point[0],
    y: point[1],
    data: text,
  }
  return labelEl
}

export default {
  props: ['dataSet', 'scale_factor', 'summarySet', 'provider'],
  data() {
    return {
      xlines: [],
      ylines: [],
      xlabels: [],
      ylabels: [],
      circles: [],
      mainline: null,
      meanline: null,
      trendline: null,
      posStdLine: null,
      negStdLine: null,
      summary: {
        meanline: null,
        posStdLine: null,
        negStdLine: null,
        trendline: null,
        mainline: null,
        circles: [],
      },
    }
  },
  watch: {
    dataSet: function () {
      this.addPointsToSVG()
      this.addMainlineToSVG()
      this.addTrendlineToSVG()
      // this.addSummaryMainlineToSVG()
      // this.addSummaryTrendlineToSVG()
      // this.addSummaryPointsToSVG()
      // this.addMeanlineToSVG()
      // this.addStdLinesToSVG()
    },
  },
  methods: {
    constructXGrid: function () {
      let point1, point2, lineEl, x
      const y1 = CHART_HEIGHT
      const y2 = 0

      for (let i = 0; i <= DOMAIN_SIZE; i++) {
        x = i * X_TICK_SIZE
        point1 = [x, y1]
        point2 = [x, y2]
        lineEl = createLineElementDOM(point1, point2)

        this.xlines.push(lineEl)
      }
    },
    constructYGrid: function () {
      let point1, point2, lineEl

      for (let i = 0; i <= NUM_GRID_LINES_Y; i++) {
        const y = CHART_HEIGHT - i * Y_TICK_SIZE
        const x1 = 0
        const x2 = DOMAIN_WIDTH
        point1 = [x1, y]
        point2 = [x2, y]
        lineEl = createLineElementDOM(point1, point2)
        this.ylines.push(lineEl)
      }
    },
    addXLabels: function () {
      const HEIGHT_MONTHS = CHART_HEIGHT + TOP + 25
      const HEIGHT_YEARS = HEIGHT_MONTHS + 10
      const june_2020_x = LEFT
      const jan_2021_x = LEFT + 7 * X_TICK_SIZE * 2
      const july_2021_x = LEFT + 13 * X_TICK_SIZE * 2
      const x_2020 = LEFT + 140
      const x_2021 = LEFT + 400
      let labelEL, point

      point = [june_2020_x, HEIGHT_MONTHS]
      labelEL = createLabel(point, 'June')
      this.xlabels.push(labelEL)

      point = [jan_2021_x, HEIGHT_MONTHS]
      labelEL = createLabel(point, 'Jan')
      this.xlabels.push(labelEL)

      point = [july_2021_x, HEIGHT_MONTHS]
      labelEL = createLabel(point, 'July')
      this.xlabels.push(labelEL)

      point = [x_2020, HEIGHT_YEARS]
      labelEL = createLabel(point, '2020')
      this.xlabels.push(labelEL)

      point = [x_2021, HEIGHT_YEARS]
      labelEL = createLabel(point, '2021')
      this.xlabels.push(labelEL)
    },
    addYLabels: function () {
      const category = this.dataSet.category
      const x = LEFT - 20
      const y = TOP + CHART_HEIGHT + 5
      const y_tick_size = Categories[category].yTickSize
      let labelEL, point, value, label

      for (let i = 0; i <= NUM_GRID_LINES_Y; i++) {
        value = i * Y_TICK_SIZE
        point = [x, y - value]
        label = i * y_tick_size
        labelEL = createLabel(point, label)
        this.ylabels.push(labelEL)
      }
    },
    addPointsToSVG: function () {
      this.circles = []
      const categoryDataSet = this.dataSet.data
      const vector = categoryDataSet.vector
      const points = createPoints(vector, COLOR2, this.scale_factor)
      points.forEach((point) => {
        this.circles.push(point)
      })
    },
    addMainlineToSVG: function () {
      const categoryDataSet = this.dataSet.data

      this.mainline = null

      const params = {
        color: COLOR2,
        useDashedLine: false,
      }

      this.mainline = addToSVG(categoryDataSet.path, params)
    },
    addTrendlineToSVG: function () {
      const categoryDataSet = this.dataSet.data

      this.trendline = null

      if (categoryDataSet.trendline) {
        const params = {
          color: COLOR2,
          useDashedLine: true,
          lineType: DEFAULT_DASHED_LINE,
        }

        const trendlinePath = createPathElement(
          categoryDataSet.trendline.path,
          params,
        )

        this.trendline = trendlinePath
      }
    },
    addSummaryMainlineToSVG: function () {
      this.summary.mainline = null

      const params = {
        color: COLOR1,
        useDashedLine: false,
      }

      this.summary.mainline = addToSVG(this.summarySet.path, params)
    },
    addSummaryTrendlineToSVG: function () {
      this.summary.trendline = null

      if (this.summarySet.trendline) {
        const params = {
          color: COLOR1,
          useDashedLine: true,
          lineType: DEFAULT_DASHED_LINE,
        }

        const trendlinePath = createPathElement(
          this.summarySet.trendline.path,
          params,
        )

        this.summary.trendline = trendlinePath
      }
    },
    addSummaryPointsToSVG: function () {
      this.summary.circles = []

      const vector = this.summarySet.vector
      const points = createPoints(vector, COLOR1, this.scale_factor)
      points.forEach((point) => {
        this.summary.circles.push(point)
      })
    },
    addMeanlineToSVG: function () {
      this.meanline = null
      const command = this.summarySet.meanline
      const params = {
        color: COLOR3,
        useDashedLine: true,
        lineType: DEFAULT_DASHED_LINE,
        opacity: OPACITY1,
      }

      this.meanline = addToSVG(command, params)
    },
    addStdLinesToSVG: function () {
      this.posStdLine = null
      this.negStdLine = null
      const command1 = this.summarySet.negStdLine
      const command2 = this.summarySet.posStdLine
      const params = {
        color: COLOR3,
        useDashedLine: true,
        lineType: DEFAULT_DASHED_LINE,
        opacity: OPACITY1,
      }

      this.posStdLine = addToSVG(command1, params)
      this.negStdLine = addToSVG(command2, params)
    },
  },
  computed: {
    esi: function () {
      const esi = this.dataSet.category.slice(3, 4)
      return esi
    },
    phase: function () {
      const phase = this.dataSet.category.split('_')[1].toUpperCase()
      return phase
    },
  },
  mounted() {
    this.constructXGrid()
    this.constructYGrid()
    this.addXLabels()
    this.addYLabels()
    this.addPointsToSVG()
    this.addMainlineToSVG()
    this.addTrendlineToSVG()
    // this.addMeanlineToSVG()
    // this.addStdLinesToSVG()
    this.addSummaryMainlineToSVG()
    this.addSummaryTrendlineToSVG()
    this.addSummaryPointsToSVG()
  },
}
</script>

<style scoped>
.display-container {
  --default-width: 100rem;
  --default-inset: 5rem;
  --grid-interval-large: 50px;
  --grid-interval-medium: 50px;
  --grid-interval-small: 50px;
  --default-offset: -9px;
  --chart-height: 400px;
  --chart-width: 700px;
  --chart-padding-top: 50px;
  --chart-padding-right: 50px;
  --chart-padding-bottom: 50px;
  --chart-padding-left: 50px;

  background-color: var(--color4);
  border-radius: 1rem;

  padding: 3rem;
  animation: enterElement 0.8s ease;
}

.display-container + .display-container {
  margin: 1rem 0;
}

.data-container {
  display: block;
}

.data-container h4 {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}

.panel {
  background-color: var(--color1);
  color: black;
  border-radius: 10px;
  padding: 1rem;
}

svg.chart {
  width: calc(
    var(--chart-width) + var(--chart-padding-left) + var(--chart-padding-right)
  );
  height: calc(
    var(--chart-height) + var(--chart-padding-top) + var(--chart-padding-bottom)
  );
}

svg.chart g.grid line {
  stroke: black;
  stroke-width: 1;
  opacity: 0.1;
}

svg.chart g.grid line:first-of-type {
  stroke-width: 1;
  opacity: 0.3;
}

svg.chart .labels {
  font-family: Arial;
  font-size: 1.4rem;
  kerning: 1;
}

svg.chart .labels.x-labels {
  text-anchor: middle;
}

svg.chart .labels.y-labels {
  text-anchor: end;
}

@keyframes enterElement {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.chart-container {
  position: relative;
}

.chart-container-label {
  position: absolute;
  top: 1.2rem;
}

.chart-container-label::before {
  content: '';
  height: 2px;
  position: absolute;
  top: 50%;
  width: 3em;
  transform: translateX(calc(-100% - 1em));
  /* background-color: var(--color5); */
  background-color: orange;
}

/* const COLOR1 = 'orange'
const COLOR2 = '#0074d9' */

.chart-container-label.overall {
  left: 30%;
}
.chart-container-label.provider::before {
  background-color: #0074d9;
}

.chart-container-label.provider {
  left: 60%;
}

.chart-y-axis-label {
  position: absolute;
  top: 60%;
  left: 2rem;
  transform: rotatez(-90deg);
  transform-origin: center left;
}
</style>
