<template>
  <div class="main">
    <nav id="nav" class="nav">
      <pages-navigation></pages-navigation>
    </nav>
    <div>
      <form id="form" @submit.prevent="handleFormSubmit">
        <div class="panel-stats2">
          <div class="providers">
            <div class="container apps">
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-apps-0"
                  name="providers"
                  value="Kristin Jackson"
                  hidden
                  v-model="provider"
                />
                <label for="switch-apps-0" class="gui-switch">
                  Kristin Jackson
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-apps-1"
                  name="providers"
                  value="Patrick Marinas"
                  hidden
                  v-model="provider"
                />
                <label for="switch-apps-1" class="gui-switch">
                  Patrick Marinas
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-apps-2"
                  name="providers"
                  value="Joel Mickail"
                  hidden
                  v-model="provider"
                />
                <label for="switch-apps-2" class="gui-switch">
                  Joel Mickail
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-apps-3"
                  name="providers"
                  value="Corey Page"
                  hidden
                  v-model="provider"
                />
                <label for="switch-apps-3" class="gui-switch">
                  Corey Page
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-apps-4"
                  name="providers"
                  value="Josh Roszak"
                  hidden
                  v-model="provider"
                />
                <label for="switch-apps-4" class="gui-switch">
                  Josh Roszak
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-apps-5"
                  name="providers"
                  value="Shelly Therrien"
                  hidden
                  v-model="provider"
                />
                <label for="switch-apps-5" class="gui-switch">
                  Shelly Therrien
                </label>
              </div>
            </div>
            <div class="container physicians">
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-physicians-0"
                  name="providers"
                  value="Mohammad Alzahri"
                  hidden
                  v-model="provider"
                />
                <label for="switch-physicians-0" class="gui-switch">
                  Mohammad Alzahri
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-physicians-1"
                  name="providers"
                  value="Corey Anderson"
                  hidden
                  v-model="provider"
                />
                <label for="switch-physicians-1" class="gui-switch">
                  Corey Anderson
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-physicians-2"
                  name="providers"
                  value="Maurice Holmes"
                  hidden
                  v-model="provider"
                />
                <label for="switch-physicians-2" class="gui-switch">
                  Maurice Holmes
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-physicians-3"
                  name="providers"
                  value="Pin Lam"
                  hidden
                  v-model="provider"
                />
                <label for="switch-physicians-3" class="gui-switch">
                  Pin Lam
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-physicians-4"
                  name="providers"
                  value="Roman Nieto"
                  hidden
                  v-model="provider"
                />
                <label for="switch-physicians-4" class="gui-switch">
                  Roman Nieto
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-physicians-5"
                  name="providers"
                  value="Kevin Rittger"
                  hidden
                  v-model="provider"
                />
                <label for="switch-physicians-5" class="gui-switch">
                  Kevin Rittger
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-physicians-8"
                  name="providers"
                  value="Setul Patel"
                  hidden
                  v-model="provider"
                />
                <label for="switch-physicians-8" class="gui-switch">
                  Setul Patel
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-physicians-6"
                  name="providers"
                  value="Phillip Singer"
                  hidden
                  v-model="provider"
                />
                <label for="switch-physicians-6" class="gui-switch">
                  Phillip Singer
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-physicians-7"
                  name="providers"
                  value="Terrence Wadley"
                  hidden
                  v-model="provider"
                />
                <label for="switch-physicians-7" class="gui-switch">
                  Terrence Wadley
                </label>
              </div>
            </div>
          </div>

          <div class="category">
            <div class="container esi">
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-esi-3"
                  value="3"
                  name="esi"
                  hidden
                  v-model="esi"
                />
                <label for="switch-esi-3" class="gui-switch">
                  ESI 3
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-esi-4"
                  value="4"
                  name="esi"
                  hidden
                  v-model="esi"
                />
                <label for="switch-esi-4" class="gui-switch">
                  ESI 4
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-esi-5"
                  value="5"
                  name="esi"
                  hidden
                  v-model="esi"
                />
                <label for="switch-esi-5" class="gui-switch">
                  ESI 5
                </label>
              </div>
              <div class="instructions" id="esi-instructions">
                required
              </div>
            </div>

            <div class="container phase">
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-phase-a"
                  value="a-d"
                  name="phase"
                  hidden
                  v-model="phase"
                />
                <label for="switch-phase-a" class="gui-switch">
                  A-D
                </label>
              </div>
              <div class="checkbox-box">
                <input
                  type="radio"
                  role="switch"
                  id="switch-phase-b"
                  value="ib-cc"
                  name="phase"
                  hidden
                  v-model="phase"
                />
                <label for="switch-phase-b" class="gui-switch">
                  IB-CC
                </label>
              </div>
              <div class="instructions" id="phase-instructions">
                required
              </div>
            </div>
          </div>
          <div class="container years">
            <div class="checkbox-box">
              <input
                type="checkbox"
                role="switch"
                id="switch-year-2020"
                value="2020"
                name="year"
                hidden
                v-model="years"
              />
              <label for="switch-year-2020" class="gui-switch">
                2020
              </label>
            </div>
            <div class="checkbox-box">
              <input
                type="checkbox"
                role="switch"
                id="switch-year-2021"
                value="2021"
                name="year"
                hidden
                v-model="years"
              />
              <label for="switch-year-2021" class="gui-switch">
                2021
              </label>
            </div>
          </div>
        </div>
        <div class="container actions">
          <button
            type="reset"
            class="btn data-btn reset-btn"
            id="reset-btn"
            @click="handleClose"
          >
            Clear Selections
          </button>
          <button type="submit" class="btn data-btn" id="data-btn">
            Create Data Set
          </button>
        </div>
      </form>

      <data-panel-a
        v-if="showPanel"
        :params="params"
        :points="points"
        @close-display="handleClose"
      ></data-panel-a>
    </div>
  </div>
</template>

<script>
import { sortMean } from '../scripts/sort-functions.mjs'
import { calculateStats } from '../scripts/stats-functions.mjs'
import {
  Categories,
  Providers,
  Indexes,
  Years,
} from '../scripts/global-data.mjs'
import { generateID } from '../scripts/functions.js'

import DataPanelA from './DataPanelA.vue'
import PagesNavigation from './PagesNavigation.vue'

function constructDataSetRequest(request, category) {
  const { esi, phase } = Categories[category]
  return {
    esi,
    phase,
    ...request,
  }
}

function constructProviderDataSet(request, vector, stats) {
  const { provider, provider_id, esi, phase, target, years } = request

  return {
    id: provider_id ? provider_id : 0, // what is this for?  For possible panel removal operation?
    provider: providerPretty(provider),
    esi,
    phase: phase.toUpperCase(),
    target, // what is this for? The target value that the providers are trying to meet.
    years: yearsPretty(years),
    data: vector,
    stats,
    request,
  }
}

function providerPretty(provider) {
  return provider ? provider : 'ALL'
}

function yearsPretty(years = []) {
  return years.length ? years.join(', ') : 'ALL'
}

function getRequestedDataVector(request, Records, DataIntervals) {
  const vector = []
  const { provider, esi, phase, years, range } = request
  const catIndex = `esi${esi}_${phase}`
  const dataSetIndex = Indexes[catIndex]

  for (let record of Records) {
    const alpha = isSelectedProvider(provider, record)
    const beta = isSelectedYears(years, record)
    const gamma = isAfterEqualStart(range, record, DataIntervals)
    const delta = isBeforeEqualEnd(range, record, DataIntervals)

    if (!alpha) {
      continue
    }
    if (!beta) {
      continue
    }
    if (!gamma) {
      continue
    }
    if (!delta) {
      continue
    }

    const dataPoint = record[dataSetIndex]
    vector.push(dataPoint)
  }

  return vector
}

function isAfterEqualStart(range, record, DataIntervals) {
  if (!range) return true

  const { start } = range
  if (isNaN(parseInt(start))) return true

  const [year, interval] = DataIntervals[start]

  const _interval = interval.split('-')
  const [month, day] = _interval[0].split('/')

  const record_year = record[Indexes.YEAR]
  const record_month = record[Indexes.MONTH]
  const record_day = record[Indexes.DAY]

  if (record_year < year) return false
  if (record_year > year) return true
  if (record_month < month) return false
  if (record_month > month) return true
  if (record_day < day) return false
  return true
}

function isBeforeEqualEnd(range, record, DataIntervals) {
  if (!range) return true
  const { end } = range
  if (isNaN(parseInt(end))) return true

  const [year, interval] = DataIntervals[end]

  const _interval = interval.split('-')
  const [month, day] = _interval[0].split('/')

  const record_year = record[Indexes.YEAR]
  const record_month = record[Indexes.MONTH]
  const record_day = record[Indexes.DAY]

  if (record_year > year) return false
  if (record_year < year) return true
  if (record_month > month) return false
  if (record_month < month) return true
  if (record_day > day) return false
  return true
}

function isSelectedProvider(provider, record) {
  if (!provider) return true

  const record_name = record[Indexes.PROVIDER]

  return provider === record_name
}

function isSelectedYears(years, record) {
  if (!years) return true
  const record_year = record[Indexes.YEAR]
  const _years = years.map((y) => +y)

  const selectedYears = new Set(_years.length === 1 ? _years : Years)

  return selectedYears.has(record_year)
}

function createDataPointsDOM(stats) {
  const bar_width = 15
  const bucket_size = 5

  const { distribution, min } = stats

  const bucket_distribution = createBucketDistribution(
    distribution,
    bucket_size,
  )

  return bucket_distribution.map((point, index) => {
    const pointEl = {
      style: {},
      tooltipText: null,
    }

    pointEl.style.height = `${point * 20}px`
    pointEl.style.width = `${bar_width}px`
    pointEl.tooltipText = `${bucket_size * index + min} - ${
      bucket_size * (index + 1) + min - 1
    } (${point})`

    return pointEl
  })
}

function createBucketDistribution(distribution, bucketSize) {
  const numBuckets = Math.ceil(distribution.length / bucketSize)

  let newDistribution = new Array(numBuckets).fill(0)

  for (let i = 0; i < distribution.length; i++) {
    let num_times = distribution[i]

    let bucket = Math.floor(i / bucketSize)

    newDistribution[bucket] += num_times
  }

  return newDistribution
}

function calculateCategoryStats(request, Records, DataIntervals) {
  const vector = getRequestedDataVector(request, Records, DataIntervals)

  if (!vector) return

  const stats = calculateStats(vector)

  if (!stats) return null

  return constructProviderDataSet(request, vector, stats)
}

const params = {
  sortFn: sortMean,
  barWidth: 8,
  bucketSize: 5,
  bucketGap: 2,
  maxIntervalIndex: 0,
  defaultRequest: {
    range: {
      start: 0,
      end: 0,
    },
    categories: Object.keys(Categories),
    providers: Object.keys(Providers),
  },
  minimums: {},
  maximums: {},
}

export default {
  components: { DataPanelA, PagesNavigation },

  data() {
    return {
      provider: null,
      esi: null,
      phase: null,
      years: [],
      request: null,
      showPanel: false,
      params: null,
      points: null,
    }
  },
  methods: {
    handleClose() {
      this.params = null
      this.showPanel = false
      this.provider = null
      this.esi = null
      this.phase = null
      this.years = []
      this.request = null
    },
    handleFormSubmit() {
      const Records = this.$store.getters.records
      const DataIntervals = this.$store.getters.dataIntervals

      if (!this.esi || !this.phase) {
        return
      }

      this.request = {
        provider: this.provider,
        esi: this.esi,
        phase: this.phase,
        years: this.years,
      }

      const allStats = calculateCategoryStats(
        this.request,
        Records,
        DataIntervals,
      )

      this.params = {
        ...allStats,
        id: generateID(),
      }

      if (allStats.stats.count > 0) {
        const dataPoints = createDataPointsDOM(allStats.stats)
        this.points = dataPoints
      }

      this.showPanel = true
    },
  },
  mounted() {
    const Records = this.$store.getters.records
    const DataIntervals = this.$store.getters.dataIntervals

    if (Records && DataIntervals) {
      params.maxIntervalIndex = DataIntervals.length - 1
      params.defaultRequest.range.end = DataIntervals.length - 1

      const categories = Object.keys(Categories)
      const _categorySets = {}

      categories.forEach((category) => {
        _categorySets[category] = []

        const allStats = calculateCategoryStats(
          constructDataSetRequest(params.defaultRequest, category),
          Records,
          DataIntervals,
        )

        _categorySets[category]['ALL'] = allStats

        params.minimums[category] = _categorySets[category]['ALL'].stats.min
        params.maximums[category] = _categorySets[category]['ALL'].stats.max
      })
    }
  },
}
</script>

<style scoped>
.main {
  --default-width: 100rem;

  display: flex;
}

nav {
  padding-left: 2rem;
  min-width: 22rem;
  max-width: 25rem;
}

form {
  width: var(--default-width);
  /* margin: 0 auto; */
}

.panel-stats2 {
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-around;
}

.providers,
.category {
  display: flex;
}

.container {
  padding: 1rem;
}

.container label:not(:last-of-type) {
  margin-bottom: 1rem;
}

.container.actions {
  position: relative;
  text-align: center;
  width: var(--default-width);
}

.btn.data-btn {
  /* width: clamp(20rem, 20vw, 30rem); */
  width: 20rem;
  padding: 1rem;
  font-size: 1.5rem;
}
.btn.reset-btn {
  /* width: clamp(14rem, 13vw, 25rem); */
  width: 14rem;
  position: absolute;
  left: 0;
  margin-left: 2rem;
}

.container.apps {
  min-width: 16rem;
}
.container.physicians {
  min-width: 20rem;
}
.container.esi {
  min-width: 7rem;
  position: relative;
}
.container.phase {
  min-width: 11rem;
  position: relative;
}

.container .instructions {
  position: absolute;
  left: 1.5rem;
  top: 10rem;
  font-size: 1.1rem;
}
.container .instructions.active {
  animation: pulse 1.25s infinite;
}

.checkbox-box label {
  display: block;
  padding: 0.25rem;
  opacity: 0.6;
  transition: opacity 0.25s;
}

.checkbox-box label:hover {
  cursor: pointer;
  opacity: 0.9;
}

input:checked ~ label,
input:checked ~ label:hover {
  opacity: 1;
}

.data-header {
  display: flex;
  justify-content: space-around;
}

.data-table tbody td:not(:first-of-type) {
  text-align: right;
  padding-right: 2rem;
}
</style>
