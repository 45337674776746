const CareLevels = ['3', '4', '5']
const CareIntervals = ['A-D', 'IB-CC']
const ProviderTypes = ['apps', 'physicians']
const Years = [2020, 2021]

const Providers = {
  'Kristin Jackson': {
    provider_type: 'apps',
    index: 1,
  },
  'Patrick Marinas': {
    provider_type: 'apps',
    index: 2,
  },
  'Joel Mickail': {
    provider_type: 'apps',
    index: 3,
  },
  'Corey Page': {
    provider_type: 'apps',
    index: 4,
  },
  'Josh Roszak': {
    provider_type: 'apps',
    index: 5,
  },
  'Shelly Therrien': {
    provider_type: 'apps',
    index: 6,
  },
  'Mohammad Alzahri': {
    provider_type: 'physicians',
    index: 7,
  },
  'Corey Anderson': {
    provider_type: 'physicians',
    index: 8,
  },
  'Maurice Holmes': {
    provider_type: 'physicians',
    index: 9,
  },
  'Pin Lam': {
    provider_type: 'physicians',
    index: 10,
  },
  'Roman Nieto': {
    provider_type: 'physicians',
    index: 11,
  },
  'Setul Patel': {
    provider_type: 'physicians',
    index: 15,
  },
  'Kevin Rittger': {
    provider_type: 'physicians',
    index: 12,
  },
  'Phillip Singer': {
    provider_type: 'physicians',
    index: 13,
  },
  'Terrence Wadley': {
    provider_type: 'physicians',
    index: 14,
  },
}

// const Categories = {
//   'esi3_a-d': {
//     esi: 3,
//     phase: 'a-d',
//     target: 150,
//     yTickSize: 50,
//   },
// }
const Categories = {
  'esi3_a-d': {
    esi: 3,
    phase: 'a-d',
    target: 150,
    yTickSize: 50,
  },
  'esi3_ib-cc': {
    esi: 3,
    phase: 'ib-cc',
    yTickSize: 50,
  },
  'esi4_a-d': {
    esi: 4,
    phase: 'a-d',
    target: 90,
    yTickSize: 50,
  },
  'esi4_ib-cc': {
    esi: 4,
    phase: 'ib-cc',
    yTickSize: 50,
  },
  'esi5_a-d': {
    esi: 5,
    phase: 'a-d',
    target: 60,
    yTickSize: 50,
  },
  'esi5_ib-cc': {
    esi: 5,
    phase: 'ib-cc',
    yTickSize: 50,
  },
}

const Indexes = {
  PROVIDER: 0,
  PROVIDER_TYPE: 1,
  YEAR: 2,
  MONTH: 3,
  DAY: 4,
  INTERVAL: 5,
  'esi3_a-d': 6,
  'esi3_ib-cc': 7,
  'esi4_a-d': 8,
  'esi4_ib-cc': 9,
  'esi5_a-d': 10,
  'esi5_ib-cc': 11,
}

const RecordIndexes = Indexes

// const DataIntervals = [
//   ['2020', '06/01-06/15'],
//   ['2020', '06/16-06/30'],
//   ['2020', '07/01-07/15'],
//   ['2020', '07/16-07/31'],
//   ['2020', '08/01-08/15'],
//   ['2020', '08/16-08/31'],
//   ['2020', '09/01-09/15'],
//   ['2020', '09/16-09/30'],
//   ['2020', '10/01-10/15'],
//   ['2020', '10/16-10/31'],
//   ['2020', '11/01-11/15'],
//   ['2020', '11/16-11/31'],
//   ['2020', '12/01-12/15'],
//   ['2020', '12/16-12/31'],
//   ['2021', '01/01-01/15'],
//   ['2021', '01/16-01/31'],
//   ['2021', '02/01-02/14'],
//   ['2021', '02/15-02/28'],
//   ['2021', '03/01-03/15'],
//   ['2021', '03/16-03/31'],
//   ['2021', '04/01-04/15'],
//   ['2021', '04/16-04/30'],
//   ['2021', '05/01-05/15'],
//   ['2021', '05/16-05/31'],
//   ['2021', '06/01-06/15'],
//   ['2021', '06/16-06/30'],
//   ['2021', '07/01-07/15'],
//   ['2021', '07/16-07/31'],
//   ['2021', '08/01-08/15'],
//   ['2021', '08/16-08/31'],
// ]

Object.freeze(CareLevels)
Object.freeze(CareIntervals)
Object.freeze(ProviderTypes)
Object.freeze(Years)
Object.freeze(Providers)
Object.freeze(Categories)
Object.freeze(Indexes)
// Object.freeze(DataIntervals)

export { Providers, Years, Categories, Indexes, RecordIndexes }
