<template>
  <div class="display-container">
    <div class="data-container">
      <div class="panel data-stats summary-stats-container">
        <summary-stats-container :result="result"></summary-stats-container>
      </div>
      <div class="panel data-stats provider-stats-container">
        <data-table
          :data="tableData()"
          :deltaCutoff="deltaCutoff"
          @selected-provider="selectedProvider"
        ></data-table>
      </div>
      <div class="panel distribution-container">
        <summary-data-distribution
          :distributionPoints="distributionPoints"
          :meanLineStyle="meanLineStyle"
        ></summary-data-distribution>
        <data-distribution-overlay
          :overlayPoints="overlayPoints"
          :overlayMeanLineStyle="overlayMeanLineStyle"
          :withPointerEvents="withPointerEvents"
        ></data-distribution-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryStatsContainer from './SummaryStatsContainer.vue'
import SummaryDataDistribution from './SummaryDataDistribution.vue'
import DataDistributionOverlay from './DataDistributionOverlay.vue'
import DataTable from './DataTable.vue'
import { sortMean } from '../scripts/sort-functions.mjs'

const _params = {
  barWidth: 8,
  bucketSize: 5,
  bucketGap: 2,
}

function getScalingFactor(category) {
  let sfx
  const small = 6
  const medium = 10
  // const large = 20

  switch (category) {
    case 'esi3_a-d':
      sfx = medium
      break
    case 'esi3_ib-cc':
      sfx = small + 1
      break
    case 'esi4_a-d':
      sfx = medium - 1
      break
    case 'esi4_ib-cc':
      sfx = small - 1
      break
    case 'esi5_a-d':
      sfx = medium + 1
      break
    case 'esi5_ib-cc':
      sfx = small
      break
    default:
      sfx = medium
  }

  return sfx
}

function calculateMeanLineOffset(mean, min) {
  const { bucketSize, barWidth, bucketGap } = _params
  const globalMin = min
  const offsetMean = Math.round(mean) - globalMin
  const bucketIndex = Math.floor(offsetMean / bucketSize)

  return (
    barWidth * bucketIndex +
    bucketGap * (bucketIndex - 1) +
    (offsetMean % bucketSize) * 2
  )
}

function constructDistributionPoints(category, bucketDistribution, globalMin) {
  const scalingFactor = getScalingFactor(category)

  function createPointDOM(minutes) {
    const { barWidth } = _params
    const height = `${minutes * scalingFactor}px`
    const width = `${barWidth}px`
    const pointEl = {
      style: {
        height: 0,
        width,
      },
      dataset: {
        height,
      },
    }

    return pointEl
  }

  function createToolTip(minutes, counter, min) {
    const { bucketSize } = _params
    const lower_range = bucketSize * counter + min
    const upper_range = bucketSize * (counter + 1) + min - 1

    return `${lower_range} - ${upper_range} (${minutes})`
  }

  function createBarDOM(minutes, counter) {
    const pointEl = createPointDOM(minutes)
    const toolTip = createToolTip(minutes, counter, globalMin)

    pointEl.tooltip = toolTip

    return pointEl
  }

  return bucketDistribution.map(createBarDOM)
}

export default {
  components: {
    SummaryStatsContainer,
    SummaryDataDistribution,
    DataTable,
    DataDistributionOverlay,
  },
  props: ['category', 'result', 'globalMean', 'globalMin', 'deltaCutoff'],
  data() {
    return {
      meanHeight: 0,
      distributionPoints: [],
      meanLineStyle: {
        height: '0px',
        left: '0px',
      },
      overlayPoints: [],
      overlayMeanLineStyle: {
        height: '0px',
        left: '0px',
      },
      withPointerEvents: false,
      provider: null,
    }
  },
  methods: {
    selectedProvider(provider) {
      this.provider = provider
      this.updateProvider(this.result)
    },
    updateProvider(_result) {
      const data = _result.data[this.provider]
      const bucketDistribution = data.bucketDistribution
      const scalingFactor = getScalingFactor(this.category)

      this.overlayPoints.forEach((point, index) => {
        const minutes = this.provider === 'ALL' ? 0 : bucketDistribution[index]
        const height = `${minutes * scalingFactor}px`
        point.style.height = height
      })

      if (this.provider === 'ALL') {
        this.overlayMeanLineStyle = {
          opacity: 0,
          left: 0,
          height: 0,
        }

        this.withPointerEvents = false
      } else {
        this.overlayMeanLineStyle = {
          opacity: 1,
          left: `${
            calculateMeanLineOffset(data.stats.mean, this.globalMin) + 2
          }px`,
          height: `${this.meanHeight}px`,
        }

        this.withPointerEvents = true
      }
    },
    tableData() {
      const keys = Object.keys(this.result.data)

      let _data = keys.map((key) => {
        return this.result.data[key]
      })

      _data.sort(sortMean)

      return _data
    },
    calculateMeanHeight(points) {
      let meanHeight = 0

      points.forEach((point) => {
        point.style.height = point.dataset.height
        const _height = parseInt(point.style.height)
        if (_height > meanHeight) {
          meanHeight = _height
        }
      })

      return meanHeight
    },
    init(_result) {
      this.distributionPoints = constructDistributionPoints(
        _result.category,
        _result.data['ALL'].bucketDistribution,
        this.globalMin,
      )

      this.overlayPoints = constructDistributionPoints(
        _result.category,
        _result.data['ALL'].bucketDistribution,
        this.globalMin,
      )

      this.meanHeight = this.calculateMeanHeight(this.distributionPoints)

      const mean_line_offset =
        calculateMeanLineOffset(this.globalMean, this.globalMin) + 39

      this.meanLineStyle = {
        left: `${mean_line_offset}px`,
        height: `${this.meanHeight}px`,
      }
    },
  },
  watch: {
    result: function (_result) {
      this.init(_result)

      if (this.provider) {
        this.updateProvider(_result)
      }
    },
  },
  mounted() {
    this.init(this.result)

    if (!this.$store.getters.isMaster) {
      this.selectedProvider(this.$store.getters.provider)
    }
  },
}
</script>

<style scoped>
.panel {
  background-color: var(--color1);
  color: black;
  border-radius: 10px;
  padding: 1rem;
}

.summary-stats-container {
  width: 40rem;
  /* height: 23rem; */
  align-self: start;
}

.display-container {
  background-color: var(--color4);
  border-radius: 1rem;

  padding: 3rem;
  animation: enterElement 0.8s ease;
}

.provider-stats-container {
  width: 50rem;
  max-height: 36rem;
}

.distribution-container {
  position: relative;
  display: flex;
  width: 68rem;
  height: 36rem;
  padding: var(--default-inset);
}

.data-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1rem;
}

.data-distribution {
  display: flex;
  align-items: flex-end;
  gap: 2px;
  height: 100%;
}

.data-distribution.summary {
  width: 100%;
}

@media (min-width: 80em) {
  .data-container {
    display: grid;
    grid-template-columns: 40rem 50rem;
  }

  .distribution-container {
    grid-column: 1 / 3;
    justify-self: center;
  }
}

@media (min-width: 120em) {
  .data-container {
    display: flex;
    flex-direction: row;
  }
}
</style>
