<template>
  <header>
    <div class="private" v-if="isLoggedIn">
      <div class="logo">
        <router-link to="/home" class="nav-link">
          MS4M2
        </router-link>
      </div>

      <ul class="list">
        <li>
          <router-link to="/home" class="nav-link">
            Home
          </router-link>
        </li>
        <li @click="logout" class="nav-link">
          Logout
        </li>
      </ul>
    </div>
    <div class="public" v-else>
      <div class="logo">
        MS4M2
      </div>
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.replace('/login')
    },
  },
}
</script>

<style scoped>
header .logo {
  font-size: 2.5rem;
}

header .public .logo {
  text-align: center;
  margin: 2rem 0 3rem;
}

header .logo .link {
  color: var(--color1);
}

header .private {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .private .logo {
  margin: 2rem 0 3rem 3rem;
}

header .private ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 3rem;
}

ul {
  margin: 0;
  padding: 0;
}

ul.list {
  list-style-type: none;
  font-size: 1.5rem;
}

.nav-link {
  display: block;
  text-decoration: none;
  color: var(--color1);
}

ul.list li + li {
  margin-left: 3rem;
}

.nav-link:hover {
  color: var(--color2);
  cursor: pointer;
}
</style>
