<template>
  <div class="stats-summary">
    <div class="data-output">
      <div>Providers</div>
      <div>ALL</div>
      <div>ESI</div>
      <div>{{ result.data.ALL.request.esi }}</div>
      <div>Interval</div>
      <div>{{ result.data.ALL.request.phase.toUpperCase() }}</div>

      <div>Start</div>
      <div>
        {{ result.data.ALL.range_start }}
      </div>
      <div>End</div>
      <div>
        {{ result.data.ALL.range_end }}
      </div>
    </div>
    <div class="data-output">
      <div>{{ result.data.ALL.stats.count }}</div>
      <div>Data Points</div>
      <div>{{ result.data.ALL.stats.min }}</div>
      <div>Min</div>
      <div>{{ result.data.ALL.stats.max }}</div>
      <div>Max</div>
      <div>{{ round(result.data.ALL.stats.mean) }}</div>
      <div>Mean</div>
      <div>{{ round(result.data.ALL.stats.std) }}</div>
      <div>Std</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['result'],
  methods: {
    round(data) {
      return Math.round(data)
    },
  },
}
</script>

<style scoped>
.stats-summary {
  display: flex;
}

.data-output {
  display: grid;
  align-content: start;
  padding: 1rem;
}

.data-output:first-child {
  grid-template-columns: 7rem 14rem;
}
.data-output:nth-child(2) {
  grid-template-columns: 5rem 10rem;
}

.data-output > div {
  padding: 0.25rem 0;
}

.data-output > div:nth-of-type(2n + 1) {
  text-align: right;
  padding-right: 1rem;
}
</style>
