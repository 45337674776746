<template>
  <div class="main">
    <nav id="nav" class="nav">
      <pages-navigation></pages-navigation>
      <div class="spacer"></div>
      <provider-list
        @selected-provider="selectProvider"
        v-if="showProviders"
      ></provider-list>
    </nav>
    <stats-table :provider="provider" :records="records"></stats-table>
  </div>
</template>

<script>
import StatsTable from './StatsTable.vue'
import ProviderList from './ProviderList.vue'
import { Categories, Indexes } from '../scripts/global-data.mjs'
import PagesNavigation from './PagesNavigation.vue'

function computeRecords(Records, provider) {
  const _records = []
  let _year = null

  for (let record of Records) {
    const record_provider = record[Indexes.PROVIDER]

    if (provider === record_provider) {
      const interval = record[Indexes.INTERVAL]
      const year = record[Indexes.YEAR]

      const _record = []
      const cell0 = year !== _year ? year : ''
      const cell1 = interval

      _record.push(cell0)
      _record.push(cell1)

      Object.keys(Categories).forEach((category) => {
        const _value = record[Indexes[category]]
        const _char = _value ? _value : '&ndash;'
        const _class = _value ? '' : 'center'

        _record.push({
          _char,
          _class,
        })
      })

      _records.push(_record)

      _year = year !== _year ? year : _year
    }
  }

  return _records
}

export default {
  components: { StatsTable, ProviderList, PagesNavigation },
  data() {
    return {
      provider: null,
      records: [],
      showProviders: false,
    }
  },
  methods: {
    selectProvider(provider) {
      const Records = this.$store.getters.records

      if (!Records) {
        this.$store.dispatch('autoLogout')
        return
      }

      this.provider = provider
      this.records = computeRecords(Records, this.provider)
    },
  },
  mounted() {
    if (this.$store.getters.isMaster) {
      this.showProviders = true
    } else {
      const Records = this.$store.getters.records
      this.provider = this.$store.getters.provider
      if (Records) {
        this.records = computeRecords(Records, this.provider)
      }
    }
  },
}
</script>

<style scoped>
.spacer {
  height: 2rem;
}

.main {
  display: flex;
  /* gap: clamp(3rem, 1rem + 10vw, 20rem); */
  gap: 3rem;
}

nav {
  padding-left: 2rem;
  min-width: 22rem;
  max-width: 25rem;
}
</style>
