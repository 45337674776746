<template>
  <ul id="nav-list" class="list" @click="selectProvider">
    <li data-provider-id="1">Kristin Jackson</li>
    <li data-provider-id="2">Joel Mickail</li>
    <li data-provider-id="3">Patrick Marinas</li>
    <li data-provider-id="4">Corey Page</li>
    <li data-provider-id="5">Josh Roszak</li>
    <li data-provider-id="6">Shelly Therrien</li>
    <li data-provider-id="7">Mohammad Alzahri</li>
    <li data-provider-id="8">Corey Anderson</li>
    <li data-provider-id="9">Maurice Holmes</li>
    <li data-provider-id="10">Pin Lam</li>
    <li data-provider-id="11">Roman Nieto</li>
    <li data-provider-id="15">Setul Patel</li>
    <li data-provider-id="12">Kevin Rittger</li>
    <li data-provider-id="13">Phillip Singer</li>
    <li data-provider-id="14">Terrence Wadley</li>
  </ul>
</template>

<script>
function updateProviderList(target) {
  const navItems = document.querySelectorAll('nav li')
  navItems.forEach((item) => {
    item.classList.remove('selected')
    item.classList.remove('disabled')
  })

  target.classList.add('selected')
  target.classList.add('disabled')
}

export default {
  methods: {
    selectProvider(evt) {
      const target = evt.target
      const provider = target.textContent

      updateProviderList(target)

      this.$emit('selected-provider', provider)
    },
  },
}
</script>

<style scoped>
.list {
  animation: enterElement 1s ease;
}

.list li {
  display: block;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  color: var(--color1);
  opacity: 0.6;
  transition: opacity 0.25s;
}

.list li:hover,
.list li:active {
  cursor: pointer;
  opacity: 0.9;
}

.list li.selected {
  opacity: 1;
}

.list li span {
  display: inline-block;
  transition: all 0.2s;
}
.list li:active span {
  transform: scale(0.9);
}

@keyframes enterElement {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
</style>
