<template>
  <table class="data-table">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th>Mean</th>
        <th>&#916;Std</th>
        <th>Min</th>
        <th>Max</th>
        <th>Std</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(record, _index) in data"
        :key="_index"
        class="selectable"
        :class="{
          fadeRow: record.stats.deltaStd <= deltaCutoff,
          active: provider === record.provider,
        }"
        @click="selectProvider"
      >
        <td>{{ record.provider }}</td>
        <td>{{ round(record.stats.mean) }}</td>
        <td>{{ record.stats.deltaStd }}</td>
        <td>{{ round(record.stats.min) }}</td>
        <td>{{ round(record.stats.max) }}</td>
        <td>{{ round(record.stats.std) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['data', 'deltaCutoff'],
  data() {
    return {
      provider: null,
    }
  },
  methods: {
    round(data) {
      return Math.round(data)
    },
    selectProvider(e) {
      this.provider = e.target.textContent
      this.$emit('selected-provider', this.provider)
    },
  },
}
</script>

<style scoped>
.fadeRow {
  opacity: 0.5;
}

.data-header {
  display: flex;
  justify-content: space-around;
}

.data-table {
  width: 100%;
  margin: 1rem 0;
  border-collapse: collapse;
}

.data-table thead {
  font-size: 1.3rem;
}

.data-table tbody td:first-of-type {
  padding-left: 1rem;
}

.data-table tbody td:not(:first-of-type) {
  text-align: right;
  padding-right: 1rem;
}

.data-table tr.selectable:hover {
  cursor: pointer;
  background-color: var(--color4);
  color: var(--color1);
}

.data-table tr.selectable.active {
  background-color: var(--color2);
  color: var(--color5);
  font-weight: 900;
}
</style>
