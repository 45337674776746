<template>
  <div class="display" id="graphsContainer">
    <div class="display-container">
      <div class="data-container">
        <div class="data-stats">
          <button class="display-toggle" :data-id="params.id" @click="close">
            X
          </button>
          <div class="data-output">
            <div>Provider</div>
            <div>{{ params.provider }}</div>
            <div>ESI</div>
            <div>{{ params.esi }}</div>
            <div>Phase</div>
            <div>{{ params.phase }}</div>
            <div>Years</div>
            <div>{{ params.years }}</div>
          </div>
          <div class="data-output" v-if="!hasData">
            <div class="no-records">
              No records for the specified parameters
            </div>
          </div>
          <div class="data-output" v-if="hasData">
            <div>{{ params.stats.count }}</div>
            <div>Data Points</div>
            <div>{{ params.stats.min }}</div>
            <div>Min</div>
            <div>{{ params.stats.max }}</div>
            <div>Max</div>
            <div>{{ params.stats.modes }} ({{ params.stats.mode.count }})</div>
            <div>Mode</div>
            <div>{{ params.stats.mean }}</div>
            <div>Mean</div>
            <div>{{ params.stats.std }}</div>
            <div>STD</div>
          </div>
        </div>
        <div class="data-distribution" v-if="hasData">
          <div class="distribution-display">
            <div
              class="data-point tooltip"
              v-for="(point, index) in points"
              :key="index"
              :style="point.style"
            >
              <span class="tooltiptext">{{ point.tooltipText }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['params', 'points'],
  computed: {
    hasData() {
      return this.params.stats.count > 0
    },
  },
  methods: {
    close() {
      this.$emit('close-display')
    },
  },
}
</script>

<style scoped>
.display {
  margin: 0 auto;
  padding: 1rem 3rem;
  max-width: var(--default-width);
}

.display-container {
  position: relative;
  width: max-content;
  margin: 3rem auto;

  background-color: var(--color4);
  border-radius: 1rem;

  padding: 3rem;
  animation: enterElement 0.8s ease;
}

.display-container .display-toggle {
  background-color: var(--color2);
  color: black;
  border-radius: 50%;
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 0;
  right: 0;
  transform: translate(25%, -25%) scale(1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.display-container .display-toggle:hover {
  transform: translate(25%, -25%) scale(1.1);
}

.data-container {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 1rem;
  justify-content: space-around;
}

.data-stats {
  background-color: var(--color1);
  color: black;
  width: 26rem;
  padding: 1rem;
  border-radius: 10px;
}
.data-output {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;
}

.data-output div:nth-of-type(2n + 1) {
  text-align: right;
  padding-right: 1rem;
}
.data-output div.no-records {
  grid-column: 1 / 3;
  text-align: center;
}

.data-distribution {
  background-color: var(--color1);
  color: black;
  width: 600px;
  padding: 1rem;
  margin-left: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.data-distribution h3 {
  text-align: center;
}

.distribution-display {
  margin: 1rem;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  gap: 3px;
}

.data-point {
  background-color: var(--color2);
}

.data-point:nth-of-type(1) {
  height: 30px;
}
.data-point:nth-of-type(2) {
  height: 50px;
}

/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--color5);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 30px);
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
