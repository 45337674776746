<template>
  <div class="data-distribution summary">
    <div
      class="data-point tooltip"
      v-for="(point, __index) in distributionPoints"
      :key="__index"
      :style="point.style"
    >
      <span class="tooltiptext">{{ point.tooltip }}</span>
    </div>
    <div class="distribution-mean" :style="meanLineStyle"></div>
  </div>
</template>

<script>
export default {
  props: ['distributionPoints', 'meanLineStyle'],
}
</script>

<style scoped>
.data-distribution {
  display: flex;
  align-items: flex-end;
  gap: 2px;
  height: 100%;
}

.data-distribution.summary {
  width: 100%;
}

.data-point {
  background-color: var(--color2);
  transition: height 0.5s ease;
}

.data-distribution-mean-label {
  position: absolute;
  top: 1.2rem;
}

.data-distribution-mean-label::before {
  content: '';
  height: 2px;
  position: absolute;
  top: 50%;
  width: 30%;
  transform: translateX(calc(-100% - 1rem));
  background-color: var(--color5);
}

.data-distribution-mean-label.overall {
  left: 20%;
}
.data-distribution-mean-label.provider::before {
  background-color: orange;
}

.data-distribution-mean-label.provider {
  left: 60%;
}

.distribution-mean {
  position: absolute;
  left: 0;
  width: 3px;
  bottom: var(--default-inset);
  /* background-color: black; */
  background-color: orange;
}

/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--color5);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 30px);
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
