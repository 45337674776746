import { createRouter, createWebHistory } from 'vue-router'
import Login from './components/Login.vue'
import SignUp from './components/SignUp.vue'
import StatsDisplay from './components/StatsDisplay.vue'
import Stats0 from './components/Stats0.vue'
import Stats1 from './components/Stats1.vue'
import Stats2 from './components/Stats2.vue'
import Stats3 from './components/Stats3.vue'
import NotFound from './components/NotFound.vue'
import store from './store/index.js'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      component: Login,
      meta: {
        requiresUnauth: true,
      },
    },
    {
      path: '/home',
      component: StatsDisplay,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/signup',
      component: SignUp,
      meta: {
        requiresMaster: true,
      },
    },
    {
      path: '/stats-0',
      component: Stats0,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/stats-i',
      component: Stats1,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/stats-ii',
      component: Stats2,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/stats-iii',
      component: Stats3,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/:catchAll(.*)',
      component: NotFound,
    },
  ],
})

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/login')
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/home')
  } else if (to.meta.requiresMaster && !store.getters.isMaster) {
    next('/login')
  } else {
    next()
  }
})

export default router
